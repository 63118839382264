/* ------------------------------------------------------------
    ACTIVITY 
------------------------------------------------------------ */
.header-activity {
  &.activity--center {
    justify-content: center;
    .header-activity__text-head {
      padding-left: 0;
    }
  }
}
.block-activity__grid-choose {
  background: var(--color-Gray3);
  padding: 30px ;
  border-radius: 0 0 10px 10px;
  margin: 0;
}
.grid-choose__item {
  margin-bottom: 24px;
  &.grid--color1 {
    .grid-choose__item-inner {
      background: var(--color-Primary1);
      border: 0;
      * {
        color: #fff;
      }
    }
   
  }
  &.grid--color2 {
    .grid-choose__item-inner {
      background: var(--color-Secondary1);
      border: 0;
      * {
        color: #fff;
      }
    }
   
  }
  &.grid--color3 {
    .grid-choose__item-inner {
      background: var(--color-Third1);
      border: 0;
      * {
        color: var(--color-Fourth1);
      }
    }
   
  }
  &.grid--color4 {
    .grid-choose__item-inner {
      background: var(--color-Blue1);
      border: 0;
      * {
        color: var(--color-Fourth1);
      }
    }
   
  }
  &.grid--color5 {
    .grid-choose__item-inner {
      background: var(--color-Fourth1);
      border: 0;
      * {
        color: #fff;
      }
    }
   
  }
  &.grid--color6 {
   
    .grid-choose__item-inner {
      background: var(--color-Secondary3);
      border: 0;
      * {
        color: #fff;
      }
    }
  }
 
}
.grid-choose__item-inner {
    background: var(--color-Gray8);
    border-radius: 10px;
    border: 1px solid var(--color-Gray2);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s all ease-in-out;
    width: 100%;
    &:hover {
      transform: scale(1.1);
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      cursor: pointer;
      &:checked {
        & + div {
          border: 3px solid var(--color-Seven2);
          box-shadow: 0 10px 10px rgba(0,0,0,.2);
        }
      }
    }
}


.grid-choose__check {
  width: 100%;
  text-align: center;
  padding: 30px 0;
  border-radius: 10px;
  transition: .4s all ease-in-out;
  border: 3px solid transparent;
}
.choose-number {
  font-size: 60px;
  line-height: 60px;
  font-family: var(--font-Title);
  display: flex;
  align-items: center;
  justify-content: center;
  small {
    font-size: 24px;
  }
}
.choose-text {
  font-family: var(--font-Bold);
}
.grid-choose__item--last {
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

/* activity intro */
.header-activity-banner {
  background: var(--color-Fourth2);
  padding: 60px 0;
}
.header-activity-banner__col {
  text-align: center;
  h1 {
    color: #fff;
  }
  p {
    font-size: 21px;
    line-height: 35px;
    font-family: var(--font-Bold);
    color: var(--color-Gray2)
  }
}
.header-activity__intro {
  text-align: center;
  margin-bottom: 60px;
  p {
    font-family: var(--font-Bold);
    color: var(--color-Fith2);
  }
}
.sec {
  margin-top: 60px;
}
.grid-grades {
  width: 100%;
}
.activity-image {
  position: absolute;
  top: -60px;
  right: 0;
  width: 160px;
  img {
    width: 100%;
  }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:992px) {
}
   
/* medium */
@media (max-width:768px) {
  .header-activity-banner {
    padding: 30px 0;
  }
  .activity-image {
    top: -40px;
    width: 120px;
  }
  .header-activity-banner__col{
    p {
      font-size: 16px;
      line-height: 25px;
    }
  }
}

/* Small */
@media (max-width:680px) {
  .block-activity__grid-choose {
    padding: 20px 10px ;
    margin: 0;
  } 
  .choose-number {
    font-size: 30px;
    line-height: 30px;
    small {
      font-size: 16px;
    }
  }
  .grid-choose__check {
    padding: 10px;
  }
  .header-activity {
    padding: 10px;
    &.activity--center {
      padding: 10px;
    }
  }
  .activity-image { 
    display: none;
  }
  .header-activity__intro {
    margin-bottom: 30px;
  }
  .grid-grades {
    margin: 0;
  }
  .activity-banner__body {
    padding: 0;
  }
  .header-activity-banner {
    padding: 20px 0;
  }
}