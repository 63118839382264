/* ------------------------------------------------------------
     Modal
------------------------------------------------------------ */
.btn-close {
     font-size: 20px;
}
.modal__icon {
     width: 150px;
     img {
          width: 100%;
     }
}
.modal-backdrop {
     background: var(--color-Secondary3) !important;
}
.modal--warning {
     *[data-type="success"] {
          .icon-danger,
          .icon-alert {
               display: none;
          }
     }
     *[data-type="danger"] {
          .icon-success,
          .icon-alert {
               display: none;
          }
     }
     *[data-type="alert"] {
          .icon-success,
          .icon-danger {
               display: none;
          }

     }
     .btn-close {
          position: absolute;
          top: 20px;
          right: 20px;
     }
     .modal-body {
          text-align: center;
          h4 {
               margin-bottom: 32px;
          }
          p {
               margin-bottom: 8px;
          }
     }
     .modal-footer {
          border-top: 0;
          display:flex;
          padding-bottom: 24px;
          justify-content: center;
          .btn {
               border: 0;
          }
     }
     .modal-content {
          border-radius: 10px;
          box-shadow: 0 10px 30px rgb(0 0 0 / 20%);
          border: 0;
     }
}
.modal__title-icon {
     &.modal-header {
          padding-bottom: 0;
          border-bottom: 0;
          display: flex;
          justify-content: center;
          
     }
     
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
    
}
   
/* Small */
@media (max-width:680px) {
    
}