/* ------------------------------------------------------------
    ACTIVITY 
------------------------------------------------------------ */
.block-activity__item-search {
  width: 100%;
  padding: 20px 20px 0 20px;
}
.block-activity{
  &.activity--empty {
    border-radius: 0;
  }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:992px) {
}
   
/* medium */
@media (max-width:768px) {
 
}

/* Small */
@media (max-width:680px) {
 
}