/* ------------------------------------------------------------
    PLANEACIONES FLEXIBLES
------------------------------------------------------------ */
.new-planning-header {
  padding: 60px 0;
  margin-bottom: 10px;
  width: 100%;
  //background: url(../../../../public/assets/images/background/confetti-background.svg) top left no-repeat;
}
.new-plannin-header__image {
  position: absolute;
  left: 5%;
  top: 260px;
  margin: 0;
  width: 150px;
  img {
       width: 100%;
  }
}
.new-planning-header__top {
  text-align: center;
  h1 {
       display: flex;
       justify-content: center;
       align-items: center;
  }
}
.new-header-top__title {
  color: var(--color-Primary6);
  font-size: 32px;
  line-height: 35px;
  font-family: var(--font-Bold);
  span {
       background: var(--color-Secondary1);
       color: #fff;
       padding: 0 2px 5px 2px;
  }
}

/* options */
.planning-options {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  button {
       margin: 0 8px;
  }
}
.planning-options__button {
  background: var(--color-Secondary5);
  color: var(--color-Fourth1);
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  transition: .4s all ease-in-out;
  &:hover {
       background: var(--color-Secondary4);
       span {
            color: #fff;
       }
  }
  span {
       margin-right: 4px;
       color: var(--color-Gray7);
       font-size: 21px;
       transition: .4s all ease-in-out;
  }
}

/* public */
.new-planning__public {
  margin-top: 32px;
}
.new-planning__public-col {
  display: flex;
  justify-content: center;
}
.new-planning__intro {
  margin-top: 32px;
}

/* accordion item */
.new-planning-wrapp {
  width: 100%;
}
.new-accordion-planning {
  width: 100%;
  margin-bottom: 32px;
  &.accordion--color1 {
       .card {
            .card-header {
                 background-color: var(--color-Primary5);
                 &:before {
                      background: var(--color-Primary1);
                 }
            }
       }
  }
  &.accordion--color2 {
       .card {
            .card-header {
                 background-color: var(--color-Gray8);
                 &:before {
                      background: var(--color-Primary1);
                 }
            }
       }
  }
  &.accordion--color3 {
       .card {
            .card-header {
                 background: var(--color-Fith4);
                 &:before {
                      display: none;
                 }
            }
       }
  }
  &:last-child {
       margin-bottom: 0;
  }
  .card {
       border: 0;
       box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.17);
       border-radius: 10px;
       .card-header {
            &:not(.active) {
                 border-radius: 10px;
                 &:before {
                      border-bottom-left-radius: 10px;
                 }
            }
            border: 2px solid var(--color-Gray2);
            background: var(--color-Secondary5);
            position: relative;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 8px;
            &.active {
                 .card-header__toggle {
                      transform: rotate(180deg);
                 }
            }
            &:before {
                 content: '';
                 position: absolute;
                 left: 0;
                 top: 0;
                 width: 8px;
                 height: 100%;
                 border-radius: 10px 0 0 0;
                 background: var(--color-Secondary1);
            }
            .card-header__toggle {
                 background: transparent;
                 border: 0;
            }
       }
       .card-body {
            background: #fff;
            border: 3px solid var(--color-Gray2);
            margin-top: -3px;
            padding: 20px;
            border-radius: 0 0 10px 10px;
            
       }
  }
}
.accordion-planning__title {
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 20px;
  h6 {
    
       color: var(--color-Fourth1);
  }
  p {
       color: var(--color-Fourth1);
       font-family: var(--font-Bold);
       display: flex;
       align-items: center;
       .accordion-title__extra {
            font-family: var(--font-Regular);
            color: var(--color-Gray5);
       }
       small {
            margin: 0 4px;
           
       }
  }
  .icon {
       margin-right: 6px;
       color: var(--color-Fourth1);
  }
  .toggle-complete {
       label {
            color: var(--color-Fourth1);
            span {
                 &:first-child {
                      margin-right: 8px;
                 }
            }
       }
  }
}
.accordion-header__arrow {
  background: var(--color-Gray3);
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-Fourth1);
}
.planning-row-enabled {
  &.disabled {
       opacity: .5;
       .form-input-enabled {
            pointer-events: none;
       }
  }
}
.session-complete {
  margin-bottom: 24px;
  &:last-child {
       margin-bottom: 0;
  }
  &.disabled {
       opacity: .5;
       .accordion-collapse {
            pointer-events: none;
       }
  }
}
.planning-row-form {
  width: 100%;
  margin-bottom: 24px;
  &:last-child {
       margin-bottom: 0;
  }
  .MuiFormControl-root {
       width: 100%;
       fieldset {
            border-width: 2px;
       }
  }
}
.toggle-moda-tooltip {
  background: var(--color-Fourth1);
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  &:hover {
       background: var(--color-Secondary1);
  }
  span {
       font-size: 17px;
       position: relative;
       top: 0px;
       left: 0.5px;
       margin: 0 !important;
  }
}

/* subaccordion */
.subaccordion-planning {
  margin-bottom: 16px;
  .accordion-header {
       border: 0;
       button {
            padding: 0 16px;
            background: var(--color-Six3);
            justify-content: space-between;
            border: 0;
            box-shadow: initial;
            color: var(--color-Fourth1);
            font-size: 14px;
            &[aria-expanded="true"] {
                 span {
                      transform: rotate(180deg);
                 }
            }
       }
  }
  .accordion-body {
       padding: 16px;
       background: var(--color-Gray3);
       border-bottom: 2px solid var(--color-Six3);
  }
}
.planning-row-heading {
  padding: 16px;
  background: var(--color-Fith4);
  border: 0;
  margin-bottom: 16px;
  box-shadow: initial;
  color: var(--color-Fourth1);
  font-size: 14px;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  p {
       font-family: var(--font-Bold);
       display: flex;
       align-items: center;
       color: var(--color-Fourth1);
       margin-left: 4px;
  }
}
.row-form--last {
  display: flex;
  justify-content: center;
}

.new-planning__bottom {
  padding: 40px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .btn {
       height: 40px;
       margin: 0 8px;
  }
}
.planning-row-index {
  margin-bottom: 24px;
  width: 100%;
  &:last-child {
       margin-bottom: 0;
  }
}
.planning-placeholder__top {
  background: var(--color-Gray8);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 0;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 32px;
  .btn {
       width: auto;
       padding: 0 20px;
       line-height: 40px;
       background: transparent;
  }
  p {
       text-align: center;
       font-family: var(--font-Bold);
       color: var(--color-Fourth1);
       margin-bottom: 24px;
  }
}


/* sesiones */
.planning-session {
  display: flex;
  justify-content: space-between;
  background: var(--color-Gray1);
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 16px;
  &:last-child {
       margin-bottom: 0;
  }
  article {
       p {
            font-family: var(--font-Bold);
            margin: 0;
            color: var(--color-Base);
            line-height: 20px;
            small {
                 color: var(--color-Gray5);
            }
       }
  }
}
.planning-placeholder__bottom {
  width: 100%;
}
.planning-sessions__actions {
  display: flex;
  align-items: center;
}
.planning-actions__btn {
  background: transparent;
  border: 0;
  padding: 0;
  height: 25px;
  margin-left: 16px;
  &.planning-session__edit {
       color: var(--color-Fourth1);
  }
  &.planning-session__delete {
       color: var(--color-Danger1);
  }
}

/* Resumem */
.planning-resume {
  width: 100%;
  text-align: left;
  padding: 0 30px;
}
.planning-resume__head {
  margin-bottom: 24px;
  width: 100%;
  h5 {
       color: var(--color-Gray5);
  }
}
.planning-resume__row {
  width: 100%;
  margin-bottom: 24px;
  &:last-child {
       margin-bottom: 0;
  }
  h6 {
       color: var(--color-Gray6);
  }
  ul {
       list-style: none;
       padding: 0;
       margin-top: 16px;
       margin-bottom: 16px;
       li {
            width: 100%;
            &:before {
                 content: "\f061";
                 font-family: "Font Awesome 5 Free";
                 font-weight: 900;
                 color: var(--color-Gray6);
                 margin-right: 16px;
            }
       }
  }
}

/* index */
.index-planning {
  width: 100%;
  padding: 30px;
}

/* sessions blocks */
.sessions-blocks {
  padding: 16px 30px;
}


/* Session browser */
.session-browser {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  
}
.sessionbrowser__item {
  border-radius: 10px;
  background: var(--color-Gray8);
  margin-right: 16px;
  margin-bottom: 16px;
  
  &.browser--button {
       display: flex;
       align-items: center;
       justify-content: center;
       min-height: 190px;
       > div {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            p {
                 margin-bottom: 16px;
                 color: var(--color-Fourth1);
                 font-family: var(--font-Bold);
            }
       }
       .btn--small {
            line-height: 40px;
            background: transparent;
            &:hover {
                 color: var(--color-Primary6);
            }
       }
  }
  &.browser--element {
       button {
            &:not(.btn) {
                 padding: 0;
                 background: transparent;
                 width: 100%;
                 text-align: left;
            }
       }
  }
  &.books-plan {
     padding: 10px;
     display: flex;
     flex-wrap: wrap;
     align-content: center;
     justify-content: center;
     min-height: 150px;
     p {
          margin-bottom: 10px;
          color: var(--color-Fourth1);
          font-family: var(--font-Bold);  
     }
     button {
          background: transparent;
     }
   }
}
.session-element__top {
  width: 100%;
  background: var(--color-Gray1);
  border-radius: 10px 10px 0 0;
  padding: 5px 10px;
  text-align: left;
  p {
       color: var(--color-Primary1);
       text-decoration: underline;
       font-family: var(--font-Bold);
       font-size: 14px;
       display: -webkit-box;
       -webkit-line-clamp: 1;
       -webkit-box-orient: vertical;
       overflow: hidden;
       text-overflow: ellipsis;
       &:hover {
            text-decoration: none;
       }
  }
}
.session-element__middle {
  width: 100%;
  figure {
       width: 100%;
       height: 90px;
       margin: 0;
       padding: 10px;
       border-radius: 10px;
       overflow: hidden;
       img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 10px;
            overflow: hidden;
       }
  }
}
.session-element__bottom {
  width: 100%;
  padding: 0 10px 10px 10px;
  article {
       text-align: left;
       * {
            color: var(--color-Fourth1);
       }
       p {
            margin: 0;
            font-size: 14px;
            line-height: 20px;
            font-family: var(--font-Bold);
            small {
                 font-family: var(--font-Regular);
            }
       }
  }
}
.session-element__delete {
  width: 100%;
  background: var(--color-Gray3);
  border-radius: 0 0 10px 10px;
  margin-top: 6px;
  .btn {
       color: var(--color-Danger1);
       font-family: var(--font-Bold);
       width: 100%;
       border: 0;
       &:hover {
            border: 0;
       }
  }
}


/* more sessions*/
.more-sessions {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  .btn {
       line-height: 40px;
  }
}

/* Plan nav */
.plan-nav {
  width: 220px;
  border-radius: 10px 0 0 10px;
  background: var(--color-Six1);
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 10;
  padding: 24px;
}
.plan-nav__list {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  li {
       width: 100%;
       margin-bottom: 16px;
       &:last-child {
            margin-bottom: 0;
       }
  }
}
.plan-nav__link {
  background: var(--color-Secondary1);
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-radius: 30px;
  padding: 2px;
  font-size: 14px;
  font-family: var(--font-Bold);
  transition: .4s all ease-in-out;
  &:hover,
  &.active {
       background: var(--color-Primary1);
       color: #fff;
       span {
            color: var(--color-Primary1);
       }
  }
  span {
       width: 28px;
       height: 28px;
       border-radius: 50%;
       background: #fff;
       color: var(--color-Secondary1);
       font-size: 22px;
       text-align: center;
       line-height: 28px;
       margin-right: 8px;
       transition: .4s all ease-in-out;
  }
}


/* plan nav link */
.plan-nav__click {
  position: absolute;
  top: -100px;
  left: 0;
  width: 10px;
  height: 10px;
}




/* preview */
.preview-plan.print-plan-body {
     padding-top: 120px;
     width: 100%;
     max-width: 900px;
}


/* auto save */
.new-planning__autosave {
     background: var(--color-Gray10);
     border-radius: 10px;
     padding: 10px;
     text-align: center;
     font-size: 14px;
     p {
          display: flex;
          align-items: center;
          justify-content: center;
     }
     span {
          margin-right: 8px;
          color: var(--color-Gray7);
     }
}

.asignatura-bottom-delete {
     display: flex;
     justify-content: flex-end;
}

/* btn copy */
.autocomplete-field__btn {
     display: flex;
     justify-content: flex-end;
     .btn--small{
          padding: 0;
          height: 35px;
          width: 100px;
     }
}

.planning-row-form__col.inst {
     justify-content: center;
     display: inline-flex;
     align-items: center;
     position: relative;
     .chat-button {
          width: 85px;
          top: 5px;
          right: 60px;
          cursor: auto;
     }
}

/*
:::::::
Responsive
:::::::
*/
/* Medium */
@media (max-width: 980px) {
  .planning-row-form__col {
       margin-bottom: 24px;
       &:last-child {
         margin-bottom: 0;
       }
     }
     .plan-nav  {
       display: none;
     }
}
/* Small */
@media (max-width: 768px) {
.new-plannin-header__image {
  display: none;
}
.new-header-top__title {
  font-size: 24px;
  line-height: 35px;
}

.accordion-planning__title {
  padding: 0;
  font-size: 18px;
  .icon {
       display: none;
  }
  .toggle-complete {
       flex-wrap: wrap;
       label {
            width: 100%;
       }
      
  }
  .toggle-action {
       margin-bottom: 6px;
  }
  p {
       flex-wrap: wrap;
     }
}
}
@media (max-width: 680px) {
  .new-planning-header {
       padding: 40px 0;
  }
  .new-header-top__title {
       font-size: 21px;
       line-height: 30px;
   }
   .planning-options {
       margin-top: 20px;
   }
  .new-planning__bottom {
       padding-top: 0;
       margin-top: 15px;
       .btn {
            width: 100%;
            margin-bottom: 16px;
            &:last-child {
                 margin-bottom: 0;
            }
       }
  }
  .planning-resume {
       padding-left: 0;
       padding-right: 0;
  }
  .index-planning,
  .sessions-blocks,
  .more-sessions {
       padding: 16px 0;
  }
  .new-planning__autosave {
     text-align: left;
     margin-bottom: 24px;
     p {
          justify-content: flex-start;
          align-items: flex-start;
       }
  }
}