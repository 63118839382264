/* ------------------------------------------------------------
    ACTIVITY 
------------------------------------------------------------ */
.body-activity {
  padding: 60px 0;
  position: relative;
}

/* head */
.header-activity__book {
  width: 70px;
  margin: 0;
  img {
    width: 100%;
  }
}
.header-activity {
  display: flex;
  align-items: center;
  background: var(--color-Six3);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 32px;
}
.header-activity__text-head {
  padding-left: 15px;
  p,
  h1 {
    margin: 0;
  }
  h1 {
    color: var(--color-Fourth1);
  }
}

/* block item */
.block-activity {
  background: var(--color-Gray3); 
  border-radius: 10px 10px 0 0;
  border-bottom: 4px solid var(--color-Fith1);
  margin-bottom: 32px;
 
}
.block-activity__item {
  border-bottom: 1px solid var(--color-Gray2);
  &:last-child {
    border-bottom: 0;
  }
  .modal__button {
      background: var(--color-Gray3);
      &:hover {
        background: var(--color-Six3);
      }
     
      .icon {
        background: var(--color-Secondary5);
        border-radius: 10px;
        font-size: 40px;
      }
      p {
        font-family: var(--font-Bold);
        color: var(--color-Fourth1);
        max-width: 90%;
      }
  }
}
.block-activity__item-head {
  background: var(--color-Fith1);
  padding: 10px;
  border-radius: 10px 10px 0 0;
  * {
    color: #fff;
    margin-bottom: 0;
  }
}


/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:992px) {
}
   
/* medium */
@media (max-width:768px) {
  .block-activity__item .modal__button {
    padding: 10px;
    .icon {
      font-size: 30px;
    }
  }
}

/* Small */
@media (max-width:680px) {
  .body-activity {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .row--index {
    margin: 0;
  }
  .header-activity {
    margin-bottom: 24px;
  }
  .header-activity__title {
    font-size: 18px;
    line-height: 25px
  }
  .header-activity__book {
    width: 50px;
  }
  .header-activity__text-head {
    h1 {
      font-size: 18px;
      line-height: 25px;
    }
  }
}