/* ------------------------------------------------------------
     DATEPICKER
------------------------------------------------------------ */
body {
     .timepicker {
          .MuiOutlinedInput-notchedOutline {
               border-radius: 10px;
               border-color: var(--color-Gray7);
               box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
          }
          .MuiOutlinedInput-input {
               padding: 0 14px;
               position: relative;
               top: -3px;
               height: 38px;
          }
          .MuiFormControl-root {
               padding-top: 3px;
               width: 100%;
          }
          input {
               box-shadow: initial;
          }
          .MuiInputLabel-root,
          legend {
               display: none;
          }
          
          
     }
     .MuiClock-root {
          > button {
               display: none !important;
          }
     }
}