/* ------------------------------------------------------------
     BREADCRUMB
------------------------------------------------------------ */
body {
  .breadcrumb-block {
    background: var(--color-Gray3);
    .row {
      align-items: center;
    }
  }
  .breadcrumb-block__col {
    padding: 24px 0; 
  }
  .breadcrumb-block__col-center {
    padding-left: 32px;
    border-left: 1px solid var(--color-Gray2);
  }
  .breadcrumb-block__back {
    display: flex;
    align-items: center;
    color: var(--color-Base);
    text-decoration: none;
    font-weight: 600;
    &:hover {
      color: var(--color-Primary1);
    }
    span {
      margin-right: 8px;
      color: var(--color-Gray7);
      font-size: 21px;
    }
  }
  .breadcrumb-block__list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    
    li {
      display: flex;
      &:after {
        content: ".";
        font-size: 16px;
        font-weight: bold;
        margin-left: 8px;
        margin-right: 8px;
        display: block;
      }
      a {
        text-decoration: none;
        transition: .4s all ease-in-out;
      }
      &:not(:last-child){
        a {
          color: var(--color-Base);
          &:hover {
            color: var(--color-Primary2);
          }
        }
      }
      &:last-child {
        &:after {
          display: none;
        }
        a {
          font-weight: 600;
          color: var(--color-Primary1);
          &:hover {
            color: var(--color-Primary2);
          }
        }
      }
    }
  }
  .breadcrumb-block__col-right {
    .btn {
      height: 40px;
      font-size: 14px;
      width: 100%;
    }
  }
}


/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
  .breadcrumb-block__col {
    padding: 10px 0; 
  }
  .breadcrumb-block__col-center {
    display: none;
  }
}

/* Small */
@media (max-width:680px) {
  .breadcrumb-block {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
  body .breadcrumb-block__col-right .btn {
    font-size: 12px;
    letter-spacing: -.5px;
    padding: 0;
  }
}