/* ------------------------------------------------------------
     SELECT
------------------------------------------------------------ */
body {
  select,
  .form-select {
       border-radius: 10px;
       border-color: var(--color-Gray7);
       box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
       color: var(--color-Base);
       height: 40px;
       &:focus {
            border-color: var(--color-Secondary2);
            box-shadow: 0 0 0 0.25rem rgb(96 129 226 / 8%);
       }
       &.small {
          height: 30px;
          padding: 0 10px;
       }
       &.form-select--selected {
          border-width: 2px;
          border-color: var(--color-Success1);
          box-shadow: 0 0 0 0.25rem rgb(103 191 120 / 15%);
       }
  }
}
.search-select .react-select-container  {
     &.form-select--selected {
          .react-select__control {
               border-width: 2px;
               border-color: var(--color-Success1);
               box-shadow: 0 0 0 0.25rem rgb(103 191 120 / 15%);
          }
          
     }
   
}