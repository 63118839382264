/* ------------------------------------------------------------
    TABLE
------------------------------------------------------------ */
table {
  background: $c-Gray3;
  margin: 16px 0;
  border-radius: 10px;
  thead {
    th {
      border-bottom: 1px solid $c-Gray7;
      color: $c-Secondary1;
    }
  } 
  th,
  td {
    padding: 10px;
    font-size: 14px;
    vertical-align: top;
  }
}

@media (max-width: 768px) {
 
}
@media (max-width: 680px) {
  table {

    th,
    td {
      font-size: 12px;
    }
  }
}