/* ------------------------------------------------------------
    ACTIVITY 
------------------------------------------------------------ */
.header-activity {
  &.header--empty {
    margin-bottom: 0;
    border-radius: 10px 10px 0 0;
  }
}
.grid-choose__content {
  margin-bottom: 24px;
}
.grid-choose__content-inner {
  border: 1px solid var(--color-Gray3);
  border-radius: 10px;
  position: relative;
  &.choose--color  {
    button {
      position: relative;
      padding-left: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
      &:after {
        content: '';
        width: 10px;
        background: var(--color-Gray2);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 10px 0 0 10px;
      }
    }
    .choose-label {
      color: var(--color-Gray7);
      font-family: var(--font-Regular);
      width: 100%;
      text-align: left;
      font-size: 14px;
      line-height: 14px;
    }
    article {
      width: 90%;
      flex-wrap: wrap;
      p {
        width: 100%;
        text-align: left;
        line-height: 20px;
        margin-top: 4px;
      }
    }
  }
  &.choose--color1 {
    button {
      &:after {
        background: var(--color-Primary1);
      }
    }
  }
  &.choose--color2 {
    button {
      &:after {
        background: var(--color-Third1);
      }
    }
  }
  &.choose--color3 {
    button {
      &:after {
        background: var(--color-Blue1);
      }
    }
  }
  &.choose--color4 {
    button {
      &:after {
        background: var(--color-Fourth1);
      }
    }
  }
  &.choose--color5 {
    button {
      &:after {
        background: var(--color-Secondary3);
      }
    }
  }
  .icon {
    background: var(--color-Secondary5);
    border-radius: 10px;
    font-size: 40px;
  }
 
}

.grid-choose__material {
  border-radius: 10px;
  border: 3px solid var(--color-Gray2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #fff;
  width: 100%;
  transition: .4s all ease-in-out;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  &:hover {
    border-color: var(--color-Fourth1);
  }
  p {
    font-family: var(--font-Title);
    color: var(--color-Fourth1);
    text-align: center;
  }
  article {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:only-child {
      width: 100%;
    }
  }
}
.material-book {
  margin: 0;
  width: 50px;
  img {
    width: 100%;
  }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:992px) {
}
   
/* medium */
@media (max-width:768px) {
 
}

/* Small */
@media (max-width:680px) {
  .grid-choose__content {
    padding: 0;
    margin-bottom: 16px;
  }
  .grid-choose__material p {
    font-size: 14px;
  }
  .grid-choose__content-inner.choose--color button {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}