/* ------------------------------------------------------------
    SESSION INIT
------------------------------------------------------------ */
.session-placeholder {
  margin: 32px 0;
  width: 100%;
  padding: 30px;
  height: 400px;
  border: 3px solid var(--color-Gray4);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.session-placeholder__icon {
  color: var(--color-Gray4);
  font-size: 60px;
  margin-bottom: 32px;
}
.session-placeholder__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.session-placeholder__article {
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
}
.result-block-pad{
  padding: 10px 0 60px 0;
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
 
}
   
/* Small */
@media (max-width:680px) {
     
     
}