/* ------------------------------------------------------------
     PRINT
------------------------------------------------------------ */
.toPrint {
  max-width: 1000px;
  margin: 0 auto;
  .result-block__wrapper {
    width: 100%;
  }
  .print-section__row__col {
    ul {
      list-style: number;
      ul {
        list-style: number;
      }
    }
  }
  .result-block {
    width: 100%;
  }
     .print-section {
        display: none;
        width: 100%;
     }
     .result-block__row {
      .result-block__session {
        margin-top: 24px;
        margin-bottom: 24px;
        width: 100%;
      }
      .result-block__session-tab {
      
        h4 {
          font-weight: 600;
        }
      }
    }
    .logo-print {
      img {
        width: 100%;
      }
    }
    .tab-title {
      padding: 10px;
      border-radius: 10px 10px 0 0;
      margin-bottom: 16px !important;
      -webkit-print-color-adjust: exact; 
    }
    #tab-1 {
      .tab-title {
        background: var(--color-Fith1) !important;
        color: #fff;
      }
    }
    #tab-2 {
      .tab-title {
        background: var(--color-Fourth1) !important;
        color: #fff;
      }
    }
    #tab-3 {
      .tab-title {
        background: var(--color-Secondary4) !important;
        color: #fff;
      }
    }
    #tab-4 {
      .tab-title {
        background: var(--color-Primary1) !important;
        color: #fff;
      }
    }
    #tab-5 {
      .tab-title {
        background: var(--color-Six2) !important;
        color: var(--color-Base);
      }
    } 
    .logo-print {
      width: 50px;
    }
    
    .result-print-box {
        background: var(--color-Gray3);
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 32px;
        display: block;
        width: 100%;
    }
    .result--index {
        border-bottom: 4px dashed var(--color-Gray2);
        padding-bottom: 24px;
        margin-bottom: 34px !important;
        
        
    }
    .result-block__li {
      li {
        margin-bottom: 10px;
        p {
          &:last-child {
              margin-bottom: 0;
          }
        }
      }
    }
    .result-block__session-tab {
      width: 100%;
      background: #fff;
    }
   
    
}

@-moz-document url-prefix() {
  .toPrint {
    .print-section {
      .row {
        display: block;
      }
    }
  }
  
}
/*
  :::::::
  PRINT MODE
  :::::::
*/

@page {
  size: A4;
  margin: 0;
}
@media print {  
  html, body {
    width: 210mm;
    height: 297mm;
  }
    body{
        -webkit-print-color-adjust: exact;
    }
    .toPrint {
      width: 100% !important;
      padding: 0 !important;
      .print-section {
        display: block;
        padding: 0 !important;
      }
      .result-block {
       &.container {
        max-width: 90%;
       }
      }
    }
    .header,
    .footer,
    .result-block__action,
    .result-block__share,
    .breadcrumb-block,
    .app-section__top {
      display: none !important;
    }
    .logo-print {
      display: block;
    }
    .result-block__session-tab,
    .result-block.container {
      break-inside: avoid-page;
    }
    .result-print-box {
      page-break-inside:avoid;
    }
    .print-section {
      display: block;
    }
    .modal-tooltip {
      display: none !important;
    }
}