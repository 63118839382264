/* ------------------------------------------------------------
    FAVORITES
------------------------------------------------------------ */
.tab-content {
  padding: 24px 0;
  .session-placeholder {
    margin-bottom: 40px;
  }
}
.nav-tabs .nav-link {
  background: var(--color-Gray3);
  border-radius: 10px 10px 0 0;
  color: var(--color-Base);
  font-size: 14px;
  &.active {
    background: var(--color-Gray7);
    color: #fff;
  }
}


/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
  .nav-tabs {
    display: flex;
    li {
      width: 33.33%;
    }
    .nav-link {
      width: 100%;
      font-size: 14px;
      padding: 5px;
    }
  }
  .tab-content {
    padding-top: 0;
  }
}
   
/* Small */
@media (max-width:680px) {
  .nav-tabs {
   
    .nav-link {
      font-size: 13px;
      padding: 5px 0;
    }
  }
     
}