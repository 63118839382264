/* ------------------------------------------------------------
    MEDIUM
------------------------------------------------------------ */
@media (min-width: 993px) {
  
}
@media (max-width:992px) {
    h1,
    .h1 {
        font-size: 42px;
        line-height: 50px;
    }
    h2,
    .h2 {
        font-size: 32px;
        line-height: 40px;
    }
    h3,
    .h3 {
        font-size: 24px;
        line-height: 35px;
    }
    h4,
    .h4 {
        font-size: 18px;
        line-height: 25px;
    }
    h5,
    .h5 {
        font-size: 16px;
        line-height: 25px;
    }
    .module {
        padding: 60px 0;
    }
    .app-section__top {
        margin-top: 71px;
    }
    .separator {
        &:before,
        &:after {
            width: 30%;
        }
    }
    .module-small {
        padding-bottom: 120px;
   }
   .label-title {
        margin-bottom: 32px;
   }
}
@media (max-width:768px) {
    h2,
    .h2 {
        font-size: 28px;
    }
    .single-title {
        font-size: 32px;
        line-height: 40px;
    }
}