/* ------------------------------------------------------------
     QUICK DASHBOARD
------------------------------------------------------------ */


/* welcome banner */
.welcome-banner {
     background: var(--color-Fourth2);
     margin-top: 90px;
     width: 100%;
}
.welcome-banner__image {
     width: 200px;
     margin: 0;
     position: relative;
     top: 60px;
     img {
          width: 100%;
     }
}
.welcome-banner__image-absolute {
     position: absolute;
     bottom: -90px;
     right: 0;
     width: 140px;
     img {
          width: 100%;
     }
}
.welcome-banner__col {
     display: flex;
     align-items: center;
     position: relative;
}
.welcome-banner__article {
     padding-left: 32px;
     h1 {
          color: #fff;
          text-transform: uppercase;
          font-family: var(--font-Title);
     }
     p {
          color: var(--color-Gray2);
          font-size: 24px;
          line-height: 35px;
          font-family: var(--font-Bold);
     }
}

/* Grid button init */
.grid-button-init {
     width: 100%;
     padding: 100px 0;
     // background: url(../../../../public/assets/images/background/background-white.svg) center center no-repeat;
}
.grid-button__element {
     width: 100%;
     border-radius: 10px;
     background: var(--color-Gray2);
     height: 82px;
     display: flex;
     align-items: center;
     padding: 0 30px;
     font-size: 18px;
     box-shadow: 0 4px 6px rgba(0,0,0,.2);
     transform: scale(1);
     transition: .4s all ease-in-out;
     &.button--init1 {
          background: var(--color-Primary1);
          color: #fff;
     }
     &.button--init2 {
          cursor: pointer;
          background: var(--color-Secondary1);
          color: #fff;
     }
     &.button--init3 {
          background: var(--color-Third1);
          color: var(--color-Fourth1);
     }
     &.button--init4 {
          background: var(--color-Blue1);
          color: var(--color-Fourth1);
     }
     &.button--init5 {
          background: var(--color-Primary7);
          color: #fff;
     }
     &.button--init6 {
          background: var(--color-Fith6);
          color: #fff;
     }
     &.button--init7 {
          background: var(--color-Fourth2);
          color: #fff;
     }
     &.button--init8 {
          background: var(--color-Blue3);
          color: var(--color-Fourth1);
     }
     &.button--init9 {
          background: var(--color-Primary2);
          color: #fff;
     }
     &:hover {
          transform: scale(1.1);
     }
     i {
          margin-right: 8px;
     }
     span {
          font-family: var(--font-Bold);
          width: 70%;
     }
     .asesor-arti{
          padding-left: 10px;
          width: 85%;
     }
}
.grid-button-init__col {
     margin-bottom: 24px;
}
.grid-button__image {
     width: 40px;
    border-radius: 10px;
    position: absolute;
    top: 35%;
    right: 20px;
    transform: translateY(-50%);
    box-shadow: 0 7px 10px rgba(0,0,0,.1);
    overflow: hidden;
}

.grid-sticker__image {
     width: 90px;
     position: absolute;
     top: -5%;
     right: -40px;
}

.grid-button__image-start {
     width: 40px;
     border-radius: 10px;
     position: relative;
     right: 8px;
     box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
     overflow: hidden;
}

/*
  :::::::
  Responsive
  :::::::
*/
/* Medium */
@media (max-width: 1200px) {
     .welcome-banner {
          margin-top: 80px;
     }
    
}
/* Small */
@media (max-width: 768px) {
     .welcome-banner__image-absolute {
          bottom: -120px;
          width: 110px;
     }
     .welcome-banner {
          padding: 10px 0;
     }
     .welcome-banner__article {
          h1 {
               font-size: 24px;
               line-height: 25px;
          }
          p {
               font-size: 16px;
               line-height: 25px;
          }
     }
     .welcome-banner__image {
          top: 30px;
     }
     .grid-button__element {
          height: 72px;
     }
     .grid-button-init {
          padding-bottom: 40px;
     }
     .grid-sticker__image {
          width: 80px;
     }
}


@media (max-width: 575px) {
     .grid-sticker__image {
          width: 70px;
          right: -10px;
          top: 0;
     }
}