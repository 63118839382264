/* ------------------------------------------------------------
     SOCIAL LIST
------------------------------------------------------------ */
.social-list-log {
  width: 100%;
  margin: 40px 0;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    li {
      margin: 0 8px;
    }
  }
}