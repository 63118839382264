/* ------------------------------------------------------------
     MATERIALS
------------------------------------------------------------ */
.container-materials__row {
     margin-bottom: 40px;
}
.container-materials__col {
     margin-bottom: 24px;
}
.search-result {
     text-align: center;
     margin-top: 24px;
}

/* tabs */
.tab-change {
     display: flex;
     border-bottom: 1px solid var(--color-Gray4);
     a {
          background: var(--color-Gray3);
          text-decoration: none;
          padding: 5px 15px;
          color: var(--color-Base);
          font-size: 14px;
          &.active,
          &:hover {
               background: var(--color-Gray7);
               color: #fff;
          }
          &:first-child {
               border-radius: 10px 0 0 0;
          }
          &:last-child {
               border-radius: 0 10px 0 0;
          }
     }
}
.tab-change-material {
     .nav-item {
          button {
               background: var(--color-Gray3);
               text-decoration: none;
               padding: 5px 15px;
               color: var(--color-Base);
               font-size: 14px;
               border-radius: 0;
               border: 0;
               &.active,
               &:hover  {
                    background: var(--color-Gray7);
                    color: #fff;
               }
          }
          &:first-child {
               button {
                    border-radius: 10px 0 0 0;
               }
          }
          &:last-child {
               button {
                    border-radius: 0 10px 0 0;
               }
          }
     }
}
.tab-forms {
     padding: 32px 0;
}

/* material title */
.material-title {
     background: var(--color-Gray1);
     border-radius: 10px 10px 0 0;
     padding: 5px;
     p {
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          font-size: 14px;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
          color: var(--color-Base) !important;
          margin: 0;
     }
}


/*
  :::::::
  Responsive
  :::::::
*/
/* Medium */
@media (max-width:768px) {
     
}
/* Small */
@media (max-width:680px) {
     .tab-change {
          a {
               width: 50%;
               display: flex;
               align-items: center;
               justify-content: center;
               line-height: 18px;
          }
     }
     .container-materials__row {
          overflow: hidden;
          .container-materials__col {
               padding: 0;
          }
     }
}