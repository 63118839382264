/* ------------------------------------------------------------
     HOME
------------------------------------------------------------ */

/* plans section */
.computer-device {
  width: 100%;
  height: 238px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
.step-col {
  figure {
    margin-bottom: 24px
  }
  article {
    display: flex;
    padding: 0 30px;
  }
}
.step-col__letter {
  font-size: 60px;
  line-height: 60px;
  font-family: var(--font-Title);
  color: var(--color-Secondary1);
  margin-right: 16px;
  margin-top: 0;
}
.step-col__info {
  font-size: 24px;
  line-height: 28px;
  color: var(--color-Primary1);
  margin-top: 0;
}

/* Resource section */
.resource-section {
  background: var(--color-Primary1);
  margin-bottom: 70px;
}
.resource__item {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  height: 250px;
  &:hover {
    .resource__item-image  {
      transform: scale(1.1);
    }
  }
  &.resource__item--color1 {
    .resource__item-bottom {
      background: var(--color-Blue1);
      p {
        color: var(--color-Fourth1);
      }
    }
  }
  &.resource__item--color2 {
    .resource__item-bottom {
      background: var(--color-Secondary1);
    }
  }
  &.resource__item--color3 {
    .resource__item-bottom {
      background: var(--color-Third1);
      p {
        color: var(--color-Fourth1);
      }
    }
  }
  &.resource__item--color4 {
    .resource__item-bottom {
      background: var(--color-Six1);
      p {
        color: var(--color-Fourth2);
      }
    }
  }
  button {
    background: transparent;
    border: 0;
    height: 100%;
    width: 100%;
    padding: 0;
  }
  a {
    text-decoration: none;
    display: block;
  }
}
.resource__item-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: var(--color-Fourth1);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  i {
    margin-right: 8px;
  }
  p {
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    font-family: var(--font-Bold);
  }
}
.resource__item-image {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: .4s all ease-in-out;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.resource__col {
  margin-bottom: 44px;
}


/* answer section */
.answer-section__col {
  position: relative;
  z-index: 1;
   p {
    text-align: center;
    font-size: 24px;
    line-height: 35px;
    color: var(--color-Fourth2);
    width: 60%;
    margin: 0 auto;
   }
}
.answer-section__device {
  margin: 0;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
.answer-section__image {
  position: absolute;
  padding: 0;
  margin: 0;
  width: 210px;
  &.answer--image1 {
    left: 300px;
    top: 0;
  }
  &.answer--image2 {
    right: 260px;
    bottom: 100px
  }
  img {
    width: 100%;
  }
}
.answer__row {
  position: relative;
}

/* comunity section */
.comunity-section {
  position: relative;
  min-height: 675px;
}
.comunity-section__device {
  width: 770px;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 600px;
  img {
    height: 100%;
    object-fit: contain;
    object-position: left;
    max-width: 100%;
  }
}
.comunity-section__article {
 
  color: var(--color-Primary1);  
  h3 {
    font-size: 40px;
    line-height: 45px;
    font-family: var(--font-Regular);
    margin-bottom: 32px;
    span {
      background: var(--color-Secondary1);
      color: #fff;
      padding: 0 10px;
      display: inline-flex;
    }
  }
  ol {
    padding: 0 0 0 31px;
    margin: 0 0 70px 0;
    font-size: 32px;
    list-style: disc;
    li {
      width: 100%;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


/* Testimonial section */
.testimonials-section {
  background: url(../../../../public/assets/images/background/background-confeti.svg) center center no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
}
.testimonial-section__col {
  display: flex;
  justify-content: space-around;
  padding-right: 60px;
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
}
.testimonial-section__item {
  width: 380px;
  &:first-child {
    padding-top: 100px;
  }
}
.testimonial-section__article {
  background: var(--color-Secondary1);
  border-radius: 25px;
  width: 100%;
  padding: 30px 20px 20px 20px;
  position: relative;
  min-height: 270px;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    background: url(../../../../public/assets/images/icon/elements.png) center center no-repeat;
    display: block;
    position: absolute;
    left: 20px;
    top: -37px;
    width: 68px;
    background-size: contain;
    height: 68px;
  }
  &:after {
    content: "\f0dd";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color:  var(--color-Secondary1);
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 92px;
  }
  p {
    color: #fff;
    font-family: var(--font-Medium);
    text-align: center;
    font-size: 22px;
    line-height: 30px;
  }
}
.testimonial-section__bottom {
  text-align: right;
  margin-top: 32px;
  width: 250px;
  h6 {
    color: var(--color-Primary1);
    font-size: 24px;
    line-height: 35px;
  }
  p {
    font-size: 20px;
    line-height: 30px;
  }
}
.testimonial-section__photo {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.testimonial-section__photo {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  right: -60px;
  bottom: -110px;
}

/* Product section title */
.product-section.module-small {
  overflow: hidden;
  padding-bottom: 0;
}
.product-section__title {
  margin-bottom: 85px;
}
.product-section__device {
  width: 280px;
  position: absolute;
  bottom: 20px;
  right: 60px;
  z-index: 1;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }
}
.product-section__icon {
  width: 100px;
  position: absolute;
  top: 150px;
  left: 20%;
  img {
    width: 100%;
  }
}
.product-section__info {
  position: relative;
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    background: url(../../../../public/assets/images/background/background-left-side.svg) top right no-repeat;
    position: absolute;
    background-size: 100%;
    right: 0;
    bottom: 0;
    width: 200%;
    height: 100%;
  }
  p {
    color: #fff;
    position: relative;
    z-index: 1;
    font-size: 28px;
    line-height: 32px;
    font-family: var(--font-Medium);
    width: 40%;
  }
}
.product-section__col {
  position: relative;
  padding: 0;
  padding-top: 350px;
  &.product__col--right {
    .product-section__info {
      &:before {
        content: '';
        background: url(../../../../public/assets/images/background/background-right-side.svg) top left no-repeat;
        left: -5px;
        background-size: 100%;
        right: initial;
        bottom: 0;
      }
    }
    .product-section__device {
      left: 60px;
      right: initial;
    }
    .product-section__icon {
      left: initial;
      right: 20%;
    }
    .product-section__info p {
      left: 60%;
    }
  }
}

/* faq section */
.faq-section {
  padding: 80px 0;
  .row {
    position: relative;
  }
}
.faq-section__title {
  h2 {
    color: var(--color-Primary1);
  }
}
.faq-section__tabs {
  margin-top: 24px;
  margin-bottom: 16px;
  &.nav-tabs {
    border: 0;
    .nav-link {
      background: var(--color-Primary1);
      color: #fff;
      border-radius: 10px;
      margin-right: 32px;
      font-family: var(--font-Bold);
      &.active {
        background: var(--color-Primary2);
      }
    }
  }
}
.faqs-accordion {
  .accordion-body {
    padding: 24px 0;
    * {
      color: var(--color-Primary1);
    }
  }
  .accordion-header {
    button {
      background: transparent;
      padding: 16px 0;
      border-radius: 0 !important; 
      font-family: var(--font-Medium);
      box-shadow: initial;
      color: var(--color-Secondary1);
      border-bottom: 1px solid var(--color-Secondary1);
      position: relative;
      &[aria-expanded="true"] {
        i {
          &:before {
            content: "\f068";
          }
        }
      }
      i {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.faq-section__image {
  width: 410px;
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 0;
  img {
    width: 100%;
  }
}
.faq-section__image-secondary {
  width: 170px;
  position: absolute;
  right: -60px;
  top: -40px;
  margin: 0;
  img {
    width: 100%;
  }
}


/*
  :::::::::
  RESPONSIVE
  ::::::::
*/
@media (max-width: 1300px) {
  .answer-section__image {
    &.answer--image1 {
      left: 260px;
    }
    &.answer--image2 {
      right: 210px;
    }
  }
  .comunity-section__device {
    width: 600px;
  }
  .faq-section__image-secondary {
    right: -26px;
    img {
      width: 90%;
    }
  }
}
@media (max-width: 1200px) {
  .product-section__device {
    width: 230px;
  }
  .product-section__icon {
    width: 80px;
  }
}
@media (max-width: 992px) {
  .step-col__info {
    font-size: 18px;
    line-height: 25px;
  }
  .step-col__letter {
    font-size: 50px;
    line-height: 50px;
  }
  .banner-article  {
    h3 {
      font-size: 30px;
      line-height: 35px;
    }
    p {
      font-size: 18px;
      line-height: 25px;
    }
    .text-color-max{
      color: var(--color-Third1);
    }
  }
  .answer-section__image {
    &.answer--image1 {
      left: 0;
    }
    &.answer--image2 {
      right: 0;
      bottom: 0;
    }
  }
  .answer__row {
    padding: 180px 0 230px 0;
  }
  .answer-section__col p {
    font-size: 18px;
    line-height: 25px;
  }
  .comunity-section__device {
    width: 55%;
  }
  .comunity-section__article {
    h3 {
      font-size: 32px;
      line-height: 35px;
    }
    ol {
      font-size: 24px;
    }
  }
  .testimonial-section__article {
    p {
      font-size: 18px;
      line-height: 25px;
    }
  }
  .testimonial-section__item {
    width: 43%;
  }
  .testimonial-section__photo {
    width: 100px;
    height: 100px;
    right: -60px;
    bottom: -60px;
  }
  .testimonial-section__bottom {
    width: 100%;
    text-align: center;
    h6 {
      font-size: 21px;
      line-height: 25px;
    }
    p {
      font-size: 16px;
    }
  }
  .product-section {
    .container {
      padding: 0;
      width: 100%;
      max-width: 100%;
    }
  }
  .product-section__info {
    padding-left: 10%;
  }
  .product-section__col {
    padding-top: 240px;
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
    &.product__col--right .product-section__info p {
      left: 40%;
    }
  }
  .faq-section__image {
    display: none;
  }
}
@media (max-width: 768px) {
  .step-col {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    article {
      justify-content: center;
      align-items: center;
    }
  }
  .resource-section {
    margin-top: 40px;
  }
  .answer-section__col {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .comunity-section__device {
    width: 50%;
    height: 450px;
  }
  .comunity-section {
    min-height: initial;
  }
  .testimonial-section__article {
    &:before {
      left: 20px;
      top: -27px;
      width: 48px;
      height: 48px;
    }
    p {
      font-size: 16px;
    }
  }
  .faq-section__image-secondary {
    img {
      width: 70%;
    }
  }
}
@media (max-width: 680px) {
  .resource__item {
    height: 200px;
  }
  .resource-section {
    margin-top: 32px;
  }
  .answer-section__image {
    width: 170px;
  }
  .answer__row {
    padding: 140px 0 230px 0;
  }
  .comunity-section__article {
    h3 {
      font-size: 21px;
      line-height: 30px;
    }
    ol {
      font-size: 16px;
    }
  }
  .comunity-section__device {
    width: 45%;
  }
  .testimonial-section__col {
    flex-wrap: wrap;
    padding-right: 15px;
    margin-bottom: 0;
  }
  .testimonial-section__item {
    width: 100%;
    margin-top: 40px;
    &:first-child {
      padding-top: 0;
    }
  }
  .testimonial-section__photo {
    width: 90px;
    height: 90px;
    right: 0;
    bottom: -70px;
  }
  .testimonial-section__article {
    padding: 20px;
    min-height: 220px;
  }
  .testimonial-section__bottom h6 {
    font-size: 18px;
  }
  .product-section__icon {
    left: 30px;
    width: 80px;
    top: 50px;
  }
  .product-section__device{
    width: 190px;
    right: 30px;
  }
  .product-section__info p {
    font-size: 18px;
    line-height: 21px;
  }
  .product-section__col {
    padding-top: 160px;
    &.product__col--right {
      .product-section__device {
        left: 30px;
      }
      .product-section__info p {
        left: 49%
      }
    }
  }
  .product-section__title {
    margin-bottom: 24px;
  }
  .faq-section__image-secondary {
    width: 130px;
    right: 0;
  }
  .faq-section__tabs {
    li {
      width: 50%;
      padding-right: 16px;
      button {
        margin-right: 0;
      }
    } 
  }
  .faq-section {
    padding: 50px 0;
  }
}