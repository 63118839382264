/* ------------------------------------------------------------
     RESET
------------------------------------------------------------ */
html,
body {
    -webkit-text-size-adjust: none;
    /* For iphone Landscape */
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    /* For font looks antialiased */
    -moz-osx-font-smoothing: grayscale;
    /* Moz antialiased */
    text-rendering: optimizeLegibility;
    /* optimezy fonts */
    font-family:  var(--font-Regular);
    font-size: 16px;
    line-height: 25px;
    height: 100%;  
    color: var(--color-Base); 
}

/* headings */
h1,
h2,
h3,
h4,
blockquote,
h5,
h6 {
    font-weight: bold;
    font-family:  var(--font-Bold);
}
h1,
.h1 {
    font-size: 45px;
    line-height: 55px;
}
h2,
.h2 {
    font-size: 36px;
    line-height: 45px;
}
h1,
.h1,
h2,
.h2 {
     font-family:  var(--font-Title);
}
h2,
.h2 {
    font-size: 36px;
    line-height: 45px;
}
h3,
.h3 {
    font-size: 28px;
    line-height: 35px;
}
h4,
.h4 {
    font-size: 21px;
    line-height: 30px;
}
h5,
.h5 {
    font-size: 18px;
    line-height: 25px;
}
h6,
.h6 {
    font-size: 16px;
    line-height: 25px;
}
p {
    color: var(--color-Gray12);
}
strong {
    font-family:  var(--font-Bold);
}

/* color text */
.color-gray {
    color: var(--color-Gray7);
}

/* outline */
* {
    &:focus {
        outline: none !important;
    }
}

/* Placeholder */
::-webkit-input-placeholder {
  
}
::-moz-placeholder {
   
} /* firefox 19+ */
:-ms-input-placeholder {
   
} /* ie */
:-moz-placeholder {
   
}


/* Selection */
::selection {
    color: #fff;
    background: var(--color-Primary1);
    opacity: 1 !important;
}
::-moz-selection {
    color: #fff;
    background: var(--color-Primary1);
    opacity: 1 !important;
} 

/* mx auto */
.mx-auto {
    margin: 0 auto;
}
.col-reset {
    padding: 0;
}

/* Hidden */
.hidden {
    display: none !important;
}


/* Address */
address {
    font-style: initial;
}


/* Margin */
.mt8 {
    margin-top: 8px;
}
.mt16 {
    margin-top: 16px;
}
.mt24 {
    margin-top: 24px;
}
.mt32 {
    margin-top: 32px;
}
.mt40 {
    margin-top: 40px;
}
.mb8 {
    margin-bottom: 8px;
}
.mb16 {
    margin-bottom: 16px;
}
.mb24 {
    margin-bottom: 24px;
}
.mb32 {
    margin-bottom: 32px;
}
.mb40 {
    margin-bottom: 40px;
}
.pl-0 {
    padding-left: 0 !important;
}

/* text color */
.text-color-purple {
    color: var(--color-Secondary1);
}
.text-color-primary {
    color: var(--color-Primary1);
}
.text-color-primary6 {
    color: var(--color-Primary6);
}
.span-text-color-purple {
    color: #fff;
    background: var(--color-Secondary1);
    padding: 0px 5px 5px 5px;
}