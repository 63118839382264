/* ------------------------------------------------------------
     SESSION TAB
------------------------------------------------------------ */
.sesion-accordion {
  .row {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.session-tab {
  width: 100%;
  padding: 0;
}
.session-tab__toggle {
  width: 100%;
  background: var(--color-Gray6);
  padding: 10px 50px 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0 0;
  &.tab--color1 {
    background: var(--color-Primary1);
  }
  &.tab--color2 {
    background: var(--color-Primary3);
  }
  &.tab--color3 {
    background: var(--color-Secondary3);
  }
  &.tab--color4 {
    background: var(--color-Fourth1);
  }
  &.tab--color5 {
    background: var(--color-Fith2);
  }
  h6,
  p {
    color: #fff;
    margin: 0;
  }
}
.session-tab__open {
  padding: 32px 15px;
  border: 1px solid var(--color-Gray2);
  border-top: 0;
  border-radius: 0 0 10px 10px !important;
}
.session-tab__open-row {
  margin-bottom: 24px;
}
.session-tab__col {
  margin-bottom: 24px;
}

/* accordion */
.accordion-header {
  .icon {
    position: absolute;
    top: 7px;
    right: 0;
    font-size: 31px;
    color: #fff;
  }
}
.accordion-button {
  background-color: transparent;
  padding: 0;
  &:after {
    display: none;
  }
  .icon {
    transform: rotate(180deg);
  }
  &.collapsed {
    .icon {
      transform: rotate(0deg);
    }
    .session-tab__toggle {
      border-radius: 10px;
    }
  }
}
.accordion-body {
  padding: 0;
  
}
.accordion-item {
  border: 0;
}


.session-info {
  background: var(--color-Gray3);
  border-radius: 10px;
  padding: 10px;
  width: 400px;
  p {
    width: 100%;
    display: block;
    margin: 0;
    font-size: 14px;
    font-style: italic;
    strong {
      color: var(--color-Primary1);
    }
    
  }
}
.sesion-accordion {
  width: 100%;
}


/*
  :::::::
  Responsive
  :::::::
*/


/* Small */
@media (max-width:680px) {
  .sesion-accordion {
    padding: 0 15px;
  }
  .session-tab__open {
    padding: 10px;
  }
  .session-tab__toggle { 
    padding-right: 30px;
  }
  .session-tab__open-row {
    margin-bottom: 16px;
  }
  .session-info {
    width: 90%;
  }
}