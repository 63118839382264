/* ------------------------------------------------------------
     BUTTON ACTION
------------------------------------------------------------ */
.btn {
  &.btn--action {
    display:  flex;
    align-items: center;
    color: var(--color-Base);
    padding: 0;
    border: 1px solid var(--color-Gray4);
    padding: 5px 15px;
    border-radius: 30px;
    background: #fff;
    &:hover {
      background: var(--color-Gray3);
    }
    span {
      color: var(--color-Base);
      margin-left: 8px;
    }
    p {
      margin: 0;
      font-weight: 600;
      color: var(--color-Base);
      transition: .4s all ease-in-out;
    }
  }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width: 680px) {
  .btn {
    &.btn--action {
      width: 100px;
      justify-content: center;
      p {
        display: none;
      }
      span {
        margin: 0;
      }
    }
  }
}