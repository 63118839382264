/* ------------------------------------------------------------
    ACTIVITY 
------------------------------------------------------------ */
.game-layout {
  padding: 60px 0;
  width: 100%;
}
.game__image {
  margin-bottom: 24px;
  width: 100%;
  height: 230px;
  border-radius: 10px;
  overflow: hidden;
  img {
    width: auto;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.game__info {
  width: 100%;
}
.game__article {
  width: 100%;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--color-Gray4);
  padding-bottom: 16px;
  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
  h6 {
    color: var(--color-Secondary1);
    margin-bottom: 24px;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  img {
    max-width: 100%;
  }
  .resource-copyright {
    padding-top: 15px;
    p {
      font-size: 12px;
    }
  }
}
.game__aside {
  width: 100%;
  margin-bottom: 32px;
  position: sticky;
  top: 100px;
  .alert {
    margin-top: 32px;
  }
}
.game__aside-title {
  width: 100%;
  margin-bottom: 16px;
  p {
    &:not(.game__aside-label) {
      font-weight: 600;
      color: var(--color-Base);
    }
    &.pacomax {
      background: var(--color-Primary1);
      border-radius: 20px;
      width: 100px;
      text-align: center;
      margin-top: 8px;
      color: #fff;
    }
  }
}
.game__aside-label {
  font-size: 14px;
  line-height: 18px;
  color: var(--color-Gray7);
  margin-bottom:0;
}
.game__aside-actions {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  > * {
    margin-right: 24px;
  }
}
.game__aside-time {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  span {
    font-size: 21px;
    margin-right: 4px;
    color: var(--color-Gray7);
  }
}
.game__list {
  margin-top: 32px;
  width: 100%;
}
.game__list-item {
  margin-bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    z-index: 1;
    pointer-events: none;
  }
  &:hover {
    img {
      transform: scale(1.3);
    }
  }
  button {
    margin: 0;
    padding: 0;
    background: transparent;
    border: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: .4s all ease-in-out;
  }
}
.game__list-view {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  border: 0;
  padding: 0;
  background-color: transparent;
  color: #fff;
  width: 30px;
  height: 30px;
  z-index: 2;
  span {
    font-size: 30px;
    
  }
}
.game-info-mobile,
.game__aside-add {
  display: none;
}
.game__aside-add {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  margin: 0;
  color: var(--color-Primary1);
  background: transparent;
  span {
    font-size: 30px;
  }
}
.game__link {
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
  .icon {
    margin-left: 8px;
  }
  a {
    background: var(--color-Gray6);
    color: #fff;
    padding: 5px 15px;
    text-decoration: none;
    border-radius: 30px;
    &:hover {
      color: #fff;
      background: var(--color-Gray7);
    }
  }
}
.game__link-external {
  background: var(--color-Gray6);
  color: #fff;
  padding: 5px 15px;
  text-decoration: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    margin-left: 4px;
  }
  &:hover {
    color: #fff;
    background: var(--color-Gray7);
  }
}
.game__aside-info {
  color: var(--color-Gray6);
  p,
  .icon {
    color: var(--color-Gray6);
  }
  p {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    font-size: 14px;
    background: var(--color-Gray3);
    font-weight: 600;
    justify-content: center;
  }
  .icon {
    margin-left: 8px;
  }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:992px) {
  .game__list-col {
    margin-bottom: 24px;
  }
}
   
/* medium */
@media (max-width:768px) {
  .game__aside {
    position: relative;
    top: 0;
  }
  .game__col-right {
    display: none;
  }
  .game-info-mobile,
  .game__aside-add {
    display: block;
  }
  .game__aside-actions {
    .check-like,
    .game__aside-time {
      margin-top: 13px;
    }
  }
}

/* Small */
@media (max-width:680px) {
  .game-layout {
    padding-top: 32px;
  }
  .game__image {
    height: 200px;
  }
  .game__info {
    background: var(--color-Gray3);
    padding: 15px;
  }
  .game__list-item {
    height: 120px;
  }
  .game__aside .alert {
    margin-top: 0;
  }
  .game__aside-actions {
    margin-bottom: 16px;
  }
}