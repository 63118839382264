/* ------------------------------------------------------------
     Modal Info
------------------------------------------------------------ */

.modal-image-paco {
  position: relative;
  margin-top: -165px;
  height: 185px;
  padding: 0;
  img {
    width: 100%;
    height: 100%;
  }
}

.modal.modal--info .modal-body {
  //margin-top: -35px;
}

/*
:::::::
Responsive
:::::::
*/

/* Small medium */
@media (max-width:768px) {
 
}

/* Small */
@media (max-width:680px) {
  .modal-image-paco  {
      height: 140px;
      margin-top: -135px;
  }
}