/* ------------------------------------------------------------
     TOOLTIP
------------------------------------------------------------ */
.tooltip-trigger {
  margin-left: 8px;
  border: 0;
  padding: 0;
  height: 25px;
  width: 25px;
  background: transparent;
  &:hover {
    span {
      color: var(--color-Primary2);
    } 
  }
  .icon {
    color: var(--color-Primary1);
  }
  
}
.tooltip-contain {
  display: flex;
  align-items: center;
}
.tooltip-arrow {
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.tooltip__span {
  display: none;
}

@media (max-width: 768px) {
  .tooltip-contain {
    flex-direction: column;
    align-items: baseline;
  }
  .tooltip__span {
    display: block;
    position: relative;
    margin-left: 3px;
    top: 1px;
  }
  
}