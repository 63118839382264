/* ------------------------------------------------------------
    INSTRUMENTOS
------------------------------------------------------------ */

/* Strategy */
.strategy-image {
  width: 140px;
  &.strategy--left {
    right: initial;
    left: 0;
  }
}

/* area title */
.area-title__button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

/* strategie */
.strategie--title {
  margin-bottom: 24px;
  h4 {
    color: var(--color-Fourth1);
    display: flex;
  }
}
.strategie--footer {
  p, a {
    color: var(--color-Fourth1);
    text-align: end;
  }
}
.strategie__col {
  margin-bottom: 24px;
}
.strategie--chat {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  .chat-section {
    position: relative;
    width: auto;
    right: initial;
    h6,
    p {
      text-align: left;
      padding-right: 3px;
    }
    .chat-info {
      padding: 12px 70px 12px 18px;
      height: 51px;
    }
  }
}

/* detail */
.custom-content__head {
  padding: 40px 0;
  width: 100%;
  h3 {
    margin-bottom: 32px;
  }
  .planning-options {
    justify-content: flex-end;
  }
}
.custom-content__body {
  width: 100%;
  margin-bottom: 40px;
}

.form__row-actions {
  display: flex;
  justify-content: center;
  margin: 24px 0 ;
  .btn {
    margin: 0 8px;
  }
}
.form__row {
  &.form__row--bottom {
    position: relative;
    margin-top: 32px;
    .strategy-image {
      bottom: 70px;
      right: -90px;
      width: 170px;
    }
  }
}
.modal-info__bottom  {
  &.modal-info--border {
    border-radius: 10px;
  }
}


/* search engine */
.search__engine__results {
  text-align: center;
  font-family: var(--font-Bold);
  color: var(--color-Gray7);
  margin-top: 32px;
}

/* results */
@media (max-width: 980px) {
 
}
@media (max-width: 768px) {
  
}
@media (max-width: 680px) {
  .area-title__button {
    justify-content: center;
  }
  .custom-content__head .planning-options {
    justify-content: center;
  }
}

@media (max-width: 380px) {
  .strategie--chat {
    .chat-section {
      h6,
      .chat-info {
        padding: 3px 30px 12px 10px;
        height: 55px;
      }
    }
  }
}