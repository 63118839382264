/* ------------------------------------------------------------
     MULTISELECT
------------------------------------------------------------ */
body {
  select,
  .form-select {
       border-radius: 10px;
       border-color: var(--color-Gray7);
       box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
       color: var(--color-Base);
       height: 40px;
       &:focus {
            border-color: var(--color-Secondary2);
            box-shadow: 0 0 0 0.25rem rgb(96 129 226 / 8%);
       }
  }
}