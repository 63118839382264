/* ------------------------------------------------------------
    STRATEGIES
------------------------------------------------------------ */
.header-activity-banner {
  &.header--confetti {
    position: relative;
    .container {
      position: relative;
    }
    &:after {
      content: '';
      // background: url(../../../../public/assets/images/background/background-confeti-hero.svg) no-repeat;
      background-size: contain;
      width: 100%;
      height: 290px;
      position: absolute;
      top: 130px;
      left: 50%;
      max-width: 1000px;
      transform: translateX(-50%);
    }
  }
}
.strategy-image {
  width: 196px;
  position: absolute;
  bottom: -210px;
  right: 10%;
  img {
    width: 100%;
  }
}

/* area title */
.area-title {
  margin-bottom: 40px;
  &.area-title--decore {
    background: url(../../../../public/assets/images/background/confetti-background.svg) center center no-repeat;
    background-size: 60%;
  }
}
.area-title__image {
  width: 80px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  margin: 24px auto 0 auto;
  display: block;
  border-radius: 20px;
  overflow: hidden;
}
.search-area__title {
  margin-bottom:  60px;
  .sticker-planning {
    margin-bottom: -35px;
  }
}
.sticker-planning {
  img {
    width: 100%;
  }
}
.search-area {
  margin-bottom: 32px;
}


/* strategies form */
.strategies-forms {
  margin-bottom: 24px;
}
.strategies-indicator {
  color: var(--color-Primary1);
  font-family: var(--font-Bold);
}


/* card */
.strategy-card {
  background: var(--color-Gray10);
  border-radius: 10px;
}
.strategy-card__header {
  background: var(--color-Gray9);
  border-radius: 10px 10px 0 0;
  padding: 5px 10px;
  * {
    color: var(--color-Fourth1);
    font-family: var(--font-Bold);
  }
}
.strategy-card__footer {
  background: var(--color-Gray9);
  border-radius: 0 0 10px 10px;
  padding: 5px 10px;
  button {
    border: 0;
    background: transparent;
    color: var(--color-Primary1);
    text-decoration: underline;
    width: 100%;
    font-family: var(--font-Bold);
    &:hover {
      text-decoration: none;
    }
  }
}
.strategy-card__body {
  padding: 10px;
  width: 100%;
  .strategy-card__row {
    width: 100%;
    margin-bottom: 8px;
    padding: 0;
    * {
      color: var(--color-Fourth1);
    }
    p {
      margin: 0;
    }
  }
}
.strategy__icon {
  margin-bottom: 0;
  width: 100%;
  span {
    font-size: 30px;
    color: var(--color-Gray11);
  }
}

.strategies-result {
  margin-bottom: 32px;
}
.strategies-grid__col {
  margin-bottom: 32px;
}

/* Detail strategie */
.strategy-detail-section {
  width: 100%;
  margin-bottom: 60px;
}
.strategy-detail-section__title {
  padding: 30px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  h1 {
    background: var(--color-Fourth1);
    color: #fff;
    display: inline-block;
    padding: 10px;
  }
}
.strategy-actions {
  .planning-options {
    margin-top: 0;
  }
}
.strategy-card-item {
  border: 2px solid var(--color-Gray11);
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}
.strategy-card-item__head {
  background: var(--color-Secondary5);
  border-radius: 7px 7px 0 0;
  padding: 10px 10px 10px 20px;
  position: relative;
  * {
    color: var(--color-Fourth1);
  }
  &:before {
    content: '';
    background: var(--color-Secondary1);
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 100%;
    border-radius: 10px 0 0 0;
  }
}
.strategy-card__row {
  width: 100%;
  margin-bottom: 16px;
  padding: 20px 25px;
  h5 {
    color: var(--color-Fourth1);
    border-bottom: 1px solid var(--color-Fourth1);
    padding-bottom: 8px;
  }
}
.card-row--top {
  h5 {
    margin-bottom: 7px;
    border-bottom: 0;
    padding-bottom: 0;
  }
  small {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -10px;
  }
}
.card-row--middle {
  background: var(--color-Gray3);
  small {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -20px;
  }
  
}
.strategy-card-item__body {
  * {
    color: var(--color-Base);
  }
  h5 {
    margin-bottom: 16px;
  }
}
.strategy-article {
  width: 100%;
  margin-bottom: 32px;
  ul {
    list-style: number;
    ul {
      list-style: lower-alpha;
    }
  }
}
.strategy-article__note {
  color: var(--color-Gray5);
  * {
    color: var(--color-Gray5);
  }
}
.strategy-asset__image {
  width: 130px;
  height: 130px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    overflow: hidden;
  }
}
.strategy-asset {
  background: #fff;
  border-radius: 10px;
  padding: 30px 30px 30px 30px;
  margin: 30px 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  position: relative;
}
.strategy-asset__article {
  width: 100%;
  margin-bottom: 16px;
  h6 {
    color: var(--color-Fourth1);
    margin-bottom: 16px;
  }
}
.strategy-asset__label {
  background: var(--color-DarkBlue2);
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 340px;
  padding: 20px;
  border-radius: 10px 0 10px 0;
  align-items: center;
  p {
    color: #fff;
    font-family: var(--font-Bold);
    padding-right: 30px;
    font-size: 14px;
    line-height: 20px;
  }
  figure {
    width: 80px;
    margin: 0;
    img {
      width: 100%;
      overflow: hidden;
      border-radius: 10px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }
  }
}


/* results */
@media (max-width: 980px) {
  .strategy-asset {
    padding: 20px;
  }
  .strategy-asset__label {
    position: relative;
    width: 100%;
    border-radius: 10px;
    margin-top: 34px;
    padding: 10px 20px;
    figure {
      img {
        border-radius: 20px;
      }
    }
  }
}
@media (max-width: 768px) {
  .strategy-image {
    display: none;
  }
  .area-title {
    margin-bottom: 24px;
  }
  .search-area__title {
    margin-bottom: 40px;
  }
  .strategies-forms {
    margin-bottom: 0;
    > div {
      margin-bottom: 16px;
    }
  }
  .area-title.area-title--decore {
    background-size: 100%;
  }
}
@media (max-width: 680px) {
  .body--strategies {
    > .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .strategy-card__body {
    position: relative;
  }
  .strategy__icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: auto;
  }
  .strategy-detail-section__title h1 {
    margin-bottom: 16px;
  }
  .strategy-asset {
    padding: 15px;
    margin: 15px 0;
  }
  .card-row--top small {
    margin-bottom: 10px;
  }
  .card-row--middle small {
    margin-bottom: 5px;
  }
 
  .strategy-asset__label {
    figure {
      width: 160px;
    }
    p {
      font-size: 12px;
      line-height: 14px;
    }
  }
}