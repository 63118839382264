/* ------------------------------------------------------------
     STEP 3
------------------------------------------------------------ */
.register__col-right {
     padding-bottom: 60px;
}
.form-check label{
     width: 90%;
}
.insti-block{
     padding-bottom: 20px;
}
.school .form-check label{
     width: 99%;
     font-weight: 500;
}
.beta__image{
     display: flex;
     justify-content: flex-end;
     img{
          width: 35%;
     }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
 
}
   
/* Small */
@media (max-width:680px) {
     .register__col-right {
          padding-bottom: 30px;
     }
     
}

@media (max-width:430px) {
     .beta__image{
          img{
               width: 50%;
          }
     }
}