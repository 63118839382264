/* ------------------------------------------------------------
     STEP 2
------------------------------------------------------------ */
.register__col-right {
     padding-bottom: 60px;
}

.modal-options {
     .modal__title-icon.modal-header {
          padding: 16px 0;
          border-bottom: 1px solid var(--color-Gray4);
     }
     .modal-title {
          display: flex;
          align-items: center;
          h4 {
               margin: 0;
               font-family:  var(--font-Title);
          }
     }
     .modal__icon {
          width: 50px;
          margin-bottom: 0;
          margin-right: 8px;
     }
     .modal-body {
          text-align: center;
          h5 {
               margin: 16px 0;
          }
     }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
 
}
   
/* Small */
@media (max-width:680px) {
     .register__col-right {
          padding-bottom: 30px;
     }
     
}