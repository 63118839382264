/* ------------------------------------------------------------
     PLANEACIÓN
------------------------------------------------------------ */
.plan-item {
  width: 100%;
  padding: 0 15px;
  &[data-type="comunidad"] {
    
    .plan-item__inner {
      background: var(--color-Fith3);
    }
    .plan-item__bottom {
      background: var(--color-Fith1);
    }
    .plan-item__grade.grade--highlight {
      background: var(--color-Fith2);
    }
  }
  &[data-type="comunidad-old"] {
    
    .plan-item__inner {
      background: var(--color-Fith6);
    }
    .plan-item__bottom {
      background: var(--color-Fith3);
    }
    .plan-item__grade.grade--highlight {
      background: var(--color-Fith5);
    }
  }
  &[data-type="old"] {
    
    .plan-item__inner {
      background: var(--color-Primary4);
    }
    .plan-item__bottom {
      background: var(--color-Primary2);
    }
    .plan-item__grade.grade--highlight {
      background: var(--color-Primary3);
    }
  }
  &[data-type="propias"] {
    
    .plan-item__inner {
      background: var(--color-Secondary4);
    }
    .plan-item__bottom {
      background: var(--color-Secondary2);
    }
    .plan-item__grade.grade--highlight {
      background: var(--color-Secondary3);
    }
  }
  &[data-type="nem"] {
    .plan-item__inner {
      background: #9366B4;
    }
    .plan-item__bottom {
      background: #6F339B;
    }
    .plan-item__grade.grade--highlight {
      background: var(--color-Secondary4);
    }
  }
  &[data-type="dosificacion"] {
    .plan-item__inner {
      background: #B588FF;
    }
    .plan-item__bottom {
      background: #9D61FF;
      text-align: center;
      img {
        padding-left: 10px;
      }
    }
    .plan-item__grade.grade--highlight {
      background: #CEB0FF;
    }
    h4 {
      color: var(--color-Secondary1);
      margin: 0;
    }
    p {
      letter-spacing: 0.5px;
    }
    a {
      text-decoration: none;
    }
  }
  .check-like {
    margin-left: 15px;
  }
}
.plan-item__inner {
  background: var(--color-Gray7);
  border-radius: 10px;
  margin-bottom: 16px;
  .check-like {
    display: none;
  }
}
.plan-item__title {
  width: 100%;
  padding: 10px 15px;
  p,
  h4 {
    color: #fff;
    margin: 0;
  }
  h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.plan-item__grade{
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
  p {
    color: #fff;
    margin: 0;
    line-height: 23px;
    &:not(.plan-item__extrafont) {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .nem-text {
    color: var(--color-Secondary1);
  }
}
.pteacher {
  margin-top: -11px;
  padding: 0 15px;
  font-size: 14px;
  p {
    color: var(--color-Primary1);
  }
}
.pteacher-nem {
  margin-top: -11px;
  padding: 0 15px;
  font-size: 14px;
  p {
    color: var(--color-Third1);
  }
}
.plan-item__extrainfo {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.plan-item__bottom {
  background: var(--color-Gray6);
  border-radius: 0 0 10px 10px;
  padding: 10px 15px;
  a,
  button {
    color: #fff;
    text-decoration: underline;
    font-weight: 700;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
  button {
    background: transparent;
    border: 0;
  }
}
.plan-item__extrafont {
  font-family: var(--font-Bold);
  margin: 0;
}
.plan-item__wrapp {
  min-height: 180px;
}

/* Plan change button */
.plan-open {
  display: none;
}

.slick-slide {
  &:not([data-index="0"]) {
    .plan-open {
      display: block;
    }
    .plan-go {
      display: none;
    }
  }
}


/*
  :::::::
  Responsive
  :::::::
*/

/* Small */
@media (max-width:680px) {
  .plan-item {
    > .check-like {
      display: none;
    }
  }
  .plan-item__inner {
    padding-top: 15px;
    .check-like {
      display: block;
      p,
      span {
        color: #fff;
      }
    }
    .check-like__change figure span {
      color: #fff;
    }
    .check-like input:checked + div span.check__active {
      color: var(--color-Seven2);
    }
  }
}