/* ------------------------------------------------------------
     NAVIGATION HINTS
------------------------------------------------------------ */

.grid-services-navhints {
  width: 100%;
  padding-bottom: 40px;
  &.grid--quick {
    padding-top: 100px;
  }
  .buttons-hints {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.grid-services-navhints__btn {
  background: var(--color-Gray1);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  height: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transform: scale(1);
  transition: .4s all ease-in-out;
  &.btn--color1 {
    color: var(--color-Fourth2);
    background: var(--color-Blue3);
  }
  &.btn--color2 {
    color: #fff;
    background: var(--color-Secondary1);
  }
  &.btn--color3 {
    color: #fff;
    background: var(--color-Primary1);
  }
  &.btn--color4 {
    color: #fff;
    background: var(--color-Fourth2);
  }
  &.btn--color5 {
    color: var(--color-Fourth2);
    background: var(--color-Blue1);
  }
  &:hover {
    transform: scale(1.1);
  }
  p {
    padding-left: 10px;
    width: 78%;
    font-size: 17px;
    line-height: 20px;
    font-family: var(--font-Bold);
  }
  .icon-ia {
    width: 70%;
  }
}

.grid-services-navhints__col {
  margin-bottom: 10px;
}


.grid-services-navhints-title__col {
  margin-top: 35px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  height: 7vh;
  .title-hints {
    text-align: center; 
  }
  h1 {
    font-size: 30px;
    color: var(--color-Secondary1);
  }
  .hints-pandilla__img {
    width: 150px;
    transform: translateY(-50%);
  }
}

.grid-button-navhints__image {
 width: 40px;
 border-radius: 10px;
 position: absolute;
 top: 50%;
 right: 20px;
 transform: translateY(-50%);
 box-shadow: 0 7px 10px rgba(0,0,0,.1);
 overflow: hidden;
}

/*
  :::::::
  Responsive
  :::::::
*/

@media (max-width:1140px) {

}

@media (max-width: 768px) {
  .grid-services-navhints-title__col {  
    h1 {
      font-size: 22px;
    }
    .hints-pandilla__img {
      display: none;
    }
  }
}

@media (max-width: 680px) {
  .grid-services-navhints__btn {
    p {
      width: 75%;
      font-size: 15px;
    }
  }
}