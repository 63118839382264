/* ------------------------------------------------------------
     LOGIN
------------------------------------------------------------ */
.register__col-right {
     padding-top: 40px;
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
     .login--to-mobile {
         display: none !important; 
     }
}
   
/* Small */
@media (max-width:680px) {
     .register__col-right {
          padding-top: 24px;
     }
}