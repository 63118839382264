/* ------------------------------------------------------------
     BUTTON
------------------------------------------------------------ */
a {
  color: var(--color-Primary1);
  &:hover {
    color: var(--color-Primary2);
    text-decoration: none;
  }
}
.btn--simple-icon {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-top: 15px;
  border: 0;
  background: transparent;
  &:hover {
    span {
      &:not(.icon) {
        text-decoration: none;
      }
    }
  }
  span {
    &:not(.icon) {
      text-decoration: underline;
    }
  }
  .icon {
    font-size: 16px;
    margin-left: 8px;
    text-decoration: none;
  }
}
.btn--edition {
  display: flex;
  align-items: center;
  h1 {
    margin: 0;
  }
  span {
    background: var(--color-Primary1);
    color: #fff;
    padding: 4px;
    border-radius: 7px;
  }
}
.btn--filter {
  color: var(--color-Gray6);
  display: flex;
  align-items: center;
  border-radius: 30px;
  transition: .4s all ease-in-out;
  background: var(--color-Gray2);
  &:hover{
    background: var(--color-Gray3);
  }
}
.btn--simple-filter {
  margin: 0;
  border: 0;
  cursor: pointer;
  color: var(--color-Primary1);
  font-weight: 600;
  background: var(--color-Gray8);
  text-decoration: underline;
  border-radius: 10px;
  padding: 5px 10px;
  &:hover {
    color: var(--color-Primary2);
    background: var(--color-Gray1);
    text-decoration: none;
  }
}
.btn {
  &.btn--link {
    font-family: var(--font-Bold);
    text-decoration: underline;
    color: var(--color-Fourth1);
    border: 0;
    &:hover {
      border: 0;
      text-decoration: none;
    }
  }
  &.btn--small {
    padding: 0;
    height: 40px;
    width: 140px;
  }
  &.btn--white {
    background: #fff;
    color: var(--color-Primary1);
    border: 0;
    &:hover {
      background: var(--color-Gray2);
      color: var(--color-Primary1);
      border: 0;
    }
  }
  &.btn--auto-width {
    width: auto;
  }
  &.btn--type4 {
    background: var(--color-Third1);
    color: var(--color-Base);
    font-weight: 500;
    border-radius: 20px;
    min-width: 160px;
    border: 0;
    transition: .4s all ease-in-out;
    text-align: center;
    justify-content: center;
    &:hover {
      background: var(--color-Third2);
      color: var(--color-Base);
    }
  }
  &.btn--type5 {
    background: var(--color-Gray7);
    font-weight: 600;
    color: #fff;
    border-radius: 20px;
    min-width: 160px;
    transition: 0.4s all ease-in-out;
    border: 0;
    &:hover {
      border: 0;
      background: var(--color-Gray6);
    }
  }
  &.btn--type6 {
    background: var(--color-Fith1);
    font-weight: 600;
    color: #fff;
    border-radius: 20px;
    min-width: 160px;
    transition: .4s all ease-in-out;
    border: 0;
    display: inline-flex;
    justify-content: center;
    padding: 10px 20px;
    &:hover {
      background: var(--color-Fith2);
      color: #fff;
      border: 0;
    }
  }
  &.btn--type7 {
    background: var(--color-Secondary1);
    font-weight: 600;
    color: #fff;
    border-radius: 20px;
    min-width: 170px;
    transition: .4s all ease-in-out;
    border: 0;
    display: inline-flex;
    justify-content: center;
    padding: 8px 20px;
    &:hover {
      background: var(--color-Secondary2);
      color: #fff;
      border: 0;
    }
  }
  &.btn--type8 {
    background: var(--color-Secondary3);
    font-weight: 600;
    color: #fff;
    border-radius: 20px;
    min-width: 170px;
    transition: .4s all ease-in-out;
    border: 0;
    display: inline-flex;
    justify-content: center;
    padding: 8px 20px;
    &:hover {
      background: var(--color-Secondary2);
      color: #fff;
      border: 0;
    }
  }
  &.btn--big {
    background: var(--color-Blue1);
    color: var(--color-Secondary1);
    font-size: 20px;
    line-height: 25px;
    border-radius: 12px;
    font-family: var(--font-Bold);
    border: 0;
    &:hover {
      border-color: transparent;
      background: var(--color-Blue2);
    }
  }
  &.btn--secondary1 {
    background: var(--color-Secondary1);
    color: #fff;
    font-size: 20px;
    line-height: 25px;
    border-radius: 50px;
    padding: 10px 20px;
    font-family: var(--font-Medium);
    border: 0;
    &:hover {
      border-color: transparent;
      background: var(--color-Secondary2);
    }
  }
}

.btn--flex {
  display: flex;
  align-items: center;
  .icon {
    margin-left: 8px;
    font-size: 21px;
  }
}

.btn--tool {
  background: var(--color-Fith5);
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 30px;
  border: 0;
  color: #fff;
  &:hover {
    background: var(--color-Fith2);
    color: #fff;
  }
  span {
    font-size: 18px;
    margin-right: 8px;
  }
}

.btn--delete {
  color: var(--color-Danger1);
  background: var(--color-Danger2);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  &:hover {
    background: var(--color-Danger3);
    color: var(--color-Danger1);
  }
  span {
    margin-right: 4px;
  }
  &.btn--delete-small {
    font-size: 14px;
    span {
          font-size: 19px;
    }
  }
}


.btn--underline {
  text-decoration: underline;
  background: transparent;
  border: 0;
  color: var(--color-Gray7);
  padding: 0;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: var(--color-Gray5);
  }
}


.btn--typeGame {
  height: 40px;
  width: 100%;
  border-radius: 10px;
  text-align: center;
  line-height: 40px;
  padding: 0;
  border: 0;
  &.game--1 {
    color: #fff;
    background: var(--color-Pink100);
    &:hover {
      background: var(--color-Pink200);
    }
  }
  &.game--2 {
    color: var(--color-Fourth1);
    background: var(--color-Blue1);
    &:hover {
      background: var(--color-Blue2);
    }
  }
  &.game--3 {
    color: var(--color-Fourth1);
    background: var(--color-Third1);
    &:hover {
      background: var(--color-Third2);
    }
  }
  &.game--4 {
    color: #fff;
    background: var(--color-Primary6);
    &:hover {
      background: var(--color-Primary7);
    }
  }
}


@media (max-width: 768px) {
  .btn {
    &.btn--big {
      font-size: 16px;
      line-height: 25px;
    }
    &.btn--secondary1 {
      font-size: 16px;
    }
  }
}