/* ------------------------------------------------------------
     Modal NPS
------------------------------------------------------------ */

.modal--nps {
     .modal-dialog {
          max-width: 345px;
          
     }
     .modal-content {
          box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid var(--color-Gray2);
     }
     .modal-header  {
          position: relative;
         
     }
     .modal-title  {
          width: 100%;
          padding: 30px 0 30px 10px;
          border-bottom: 1px solid var(--color-Gray2);
          * {
               color: var(--color-Fourth1);
               font-family: var(--font-Medium);
          }
     }
     .modal__close {
          position: absolute;
          top: -20px;
          right: -20px;
     }
     .modal-footer {
          border-top: 0;
          padding-top: 0;
          justify-content: center;
     }
}
.modal-image-back {
     position: absolute;
     width: 100%;
     margin: 0;
     height: 151px;
     padding: 0;
     img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: right;
     }
}
.modal__close {
     &.close--red {
          background: var(--color-Seven2);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          span {
               color: #fff;
          }
     }
}
.modal__nps-top {
     h4 {
          color: var(--color-Fourth1);
          font-family: var(--font-Medium);
     }
}
.modal__nps-slider {
     padding: 40px 30px 20px 30px;
     position: relative;
     .MuiSlider-valueLabel {
          background: transparent;
          top: 28px;
          font-size: 16px;
          font-family: var(--font-Title);
          &::before {
               display: none;
          }
     }
     .MuiSlider-thumb {
          width: 25px;
          height: 25px;
          background: var(--color-Fourth1);
     }
     .MuiSlider-rail {
          color: var(--color-Gray6);
     }
     .MuiSlider-mark {
          display: none;
     }
     .MuiSlider-colorPrimary {
          color: transparent;
     }
     .MuiSlider-markLabel {
          font-family: var(--font-Title);
          &[data-index="0"] {
               left: 10px !important;
          }
     }
}
.modal__nps-middle {
     padding-bottom: 24px;
     h6 {
          padding-bottom: 8px;
          color: var(--color-Fourth1);
          font-family: var(--font-Medium);
     }
}
.nps-checkboxes{
     margin-right: 1.5rem;
}
.nps-checkboxes {
     padding: 20px 0;
     margin-right: 1.5rem;
}
.nps-checkbox {
     display: flex;
     flex-direction: row-reverse;
     align-items: center;
     margin-bottom: 8px;
     &:last-child {
          margin-bottom: 0;
     }
     input {
          border: 1px solid #fff;
          background: var(--color-Fourth1);
          border-radius: 50%;
          appearance: none;
          box-shadow: initial;
          margin-left: 16px;
          width: 18px !important;
          height: 18px !important;
          position: relative;
          cursor: pointer;
          border: 0;
          &:checked {
               background: var(--color-Primary1);
          }
          &:after {
               content: '';
               display: block;
               width: 27px;
               height: 27px;
               border-radius: 50%;
               border: 2px solid var(--color-Fourth1);
               position: absolute;
               top: 50%;
               left: 50%;
               transform: translate(-50%, -50%);
          }
     }
     label {
          text-align: right;
          font-family: var(--font-Medium);
          cursor: pointer;
          font-size: 14px;
          width: 80%;
     }
}
.modal__nps-bottom {
     * {
          color: var(--color-Fourth1);
          font-size: 14px; 
          font-family: var(--font-Medium);   
     }
     a {
          text-decoration: underline;
          &:hover {
               text-decoration: none;
          }
     }
}
.nps-emoji {
     position: absolute;
     top: 10px;
     margin: 0;
     font-size: 30px;
     &.emoji--left {
          left: 18px;
     }
     &.emoji--right {
          right: 14px
     }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
    
}
   
/* Small */
@media (max-width:680px) {
     .modal--nps {
          .modal-dialog {
               margin: 0 auto;
          }
          .modal__close {
               position: absolute;
               top: -10px;
               right: -10px;
           }
     }
     .modal-image-back  {
          height: 136px;
     }
}