/* ------------------------------------------------------------
    ACTIVITY 
------------------------------------------------------------ */
.activity-detail-options {
  margin-bottom: 40px;
  width: 100%;
}
.detail-button {
  border-radius: 30px;
  background: var(--color-Gray2);
  display: flex;
  align-items: center;
  padding: 5px 20px;
  transition: .4s all ease-in-out;
  &:hover {
    background: var(--color-Gray3);
  }
  .icon {
    margin-right: 4px;
    color: var(--color-Gray6);
  }
}
.activity-detail-options__item {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  p {
    width: 100%;
    text-align: center;
    font-family: var(--font-Bold);
    margin-top: 24px;
    color: var(--color-Fith2);
  }
}
.activity-detail-intro {
  width: 100%;
  margin-bottom: 32px;
}
.activity-detail-content {
  width: 100%;
}
.block-activity__article {
  padding: 10px 15px;
  width: 100%;
  img{
    max-width: 100%;
  }
}
.block-activity__item-options {
  width: 100%;
  padding: 10px 15px;
  article {
    background: #fff;
    border-radius: 10px;
    padding: 30px;
    p {
      color: var(--color-Fourth1);
      display: contents;
    }
    img{
      max-width: 100%;
    }
  }
}
.activity-pill {
  background: var(--color-Primary1);
  color: #fff;
  border-radius: 30px;
  margin-left: 8px;
  padding: 2px 5px;
  font-size: 12px;
}
.body-activity__grades{
  img{
    max-width: 100%;
  }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:992px) {
}
   
/* medium */
@media (max-width:768px) {
  
}

/* Small */
@media (max-width:680px) {
  .block-activity__item-options article {
    padding: 10px;
  }
}

@media print {
  body {
    .app--print {
      &,
      .body-activity__grades,
      .body-activity,
      .app-section__top,
      .row--index,
      .app-main {
        display: block !important;
        .detail-button {
          display: none;
        }
      }
      .block-activity {
        break-inside: avoid;
      }
    }
   
  }
  
} 