/* ------------------------------------------------------------
     DATE PICKER
------------------------------------------------------------ */
.rs-picker-toggle-textbox {

}
.rs-picker-default .rs-picker-toggle {
  border-radius: 10px;
  border-color: var(--color-Gray7);
  box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
  color: var(--color-Base);
}
.rs-btn-primary {
  border-radius: 30px;
  font-weight: 600;
}
.rs-calendar-table-cell-in-range:before {
  background-color: var(--color-Fith3);
}
.rs-picker-daterange-header {
  min-width: 280px;
}