/* ------------------------------------------------------------
    PRINT
------------------------------------------------------------ */
.session-tab--print-element {
    margin-bottom: 24px;
    div {
        background: var(--color-Gray3);
        border-radius: 10px;
        width: 100%;
        padding: 10px;
    }
    p {
        &:not(.session-tab--url) {
            color: var(--color-Base) !important;
        }
    }
    h6 {
        color: var(--color-Secondary1);
        margin-bottom: 8px;
    }
    .session-tab--url {
        color: var(--color-Fourth1);
        text-decoration: underline;
    }
}
