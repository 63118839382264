/* ------------------------------------------------------------
     INPUT
------------------------------------------------------------ */
body {
     input,
     .form-control {
          border-radius: 10px;
          border-color: var(--color-Gray7);
          box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
          color: var(--color-Base);
          height: 40px;
          &:focus {
               border-color: var(--color-Secondary2);
               box-shadow: 0 0 0 0.25rem rgb(96 129 226 / 8%);
          }
          &.form-select--selected {
               border-width: 2px;
               border-color: var(--color-Success1);
               box-shadow: 0 0 0 0.25rem rgb(103 191 120 / 15%);
          }
          &.form--hide-element {
               height: auto;
          }
     }
}

.autocomplete-field {
     width: 100%;
    box-shadow: initial;
    border: 0;
    background: var(--color-Six3);
    padding: 10px;
}
.autocomplete-field__indicator {
     background: var(--color-Blue1);
         font-size: 12px;
    border-radius: 10px;
    padding: 0 10px;
    display: inline-block;
    color: var(--color-Fourth1);
    font-family: var(--font-Medium);
    margin: 15px 0 8px 0;
}
.autocomplete-field__container {
     width: 100%;
     position: relative;
}