/* ------------------------------------------------------------
     ASIDE
------------------------------------------------------------ */
.aside-block {
  background: var(--color-Fith1);
  padding: 40px 15px 24px 0;
  position: relative;
  height: 100%;
  &:before {
    content: "";
    width: 200%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--color-Fith1);
  }
  &:after {
    background: url(../../../public/assets/images/elements/figure-1.png) top left no-repeat;
    background-size: cover;
    width: 150px;
    content: "";
    height: 30%;
    bottom: 0;
    right: 0;
    position: absolute;
    mix-blend-mode: multiply;
    opacity: .3;
  }
  h3 {
    color: #fff;
    margin-bottom: 24px;
  }
}
.aside-block__inner {
  position: relative;
  z-index: 1;
}
.aside-block__ul {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      color: var(--color-Success1);
      margin-right: 8px;
    }
    p {
      margin: 0;
      color: #fff;
    }
  }
}
.aside-block__image {
  width: 100%;
  img {
    margin-top: 32px;
    width: 80%;
  }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
  .aside-block__image {
    display: none;
  }
  .register__col-left {
    &:not(.register--to-mobile) {
      .aside-block {
        background: var(--color-Gray1);
        padding: 20px 15px;
        text-align: center;
        .aside-block__inner {
          h3 {
            color: var(--color-Base);
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .aside-block {
    &:before {
      display: none;
    }
  }
  .register--to-mobile {
    .aside-block {
      padding: 16px 15px;
      background: var(--color-Success2);
      h3 {
        color: var(--color-Success1); 
      }
    }
    .aside-block__ul {
      p {
        color: var(--color-Base);
      }
    }
  }
}

/* Small */
@media (max-width:680px) {
  .register--to-mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .aside-block {
      h3 {
        padding-left: 35px;
      }
    }
  }
  
  .register__col-left {
    padding-left: 0 !important;
    padding-right: 0 !important;
    &:not(.register--to-mobile) {
      .aside-block {
        padding: 8px 15px;
        text-align: left;
        h3 {
          font-size: 16px;
        }
      }
    }
  }
}