/* ------------------------------------------------------------
     INPUT
------------------------------------------------------------ */
body {
     textarea,
     textarea.form-control {
          border-radius: 10px;
          border-color: var(--color-Gray7);
          box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
          color: var(--color-Base);
          height: 100px;
         
          &:focus {
               border-color: var(--color-Secondary2);
               box-shadow: 0 0 0 0.25rem rgb(96 129 226 / 8%);
          }
          &.form-select--selected {
               border-width: 2px;
               border-color: var(--color-Success1);
               box-shadow: 0 0 0 0.25rem rgb(103 191 120 / 15%);
            }
     }
}
