/* ------------------------------------------------------------
    LOADER
------------------------------------------------------------ */
.loader__wrapp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  background: rgba(255,255,255,.7);
  backdrop-filter: blur(10px);
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  transition: .4s all ease-in-out;
  &[data-class="true"] {
    opacity: 1;
    z-index: 200;
    pointer-events: initial;
  }
}
.loader__arti-element {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  img {
    width: 140px;
  }
  p {
    color: var(--color-Fourth2);
    font-family: var(--font-Bold);
  }
}
.loader__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.loader {
  width: 60px;
  height: 60px;
  border: 5px solid var(--color-Gray5);
  border-bottom-color: var(--color-Primary1);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

.loader-small {
  display: flex;
  box-sizing: border-box;
  position: relative;
  margin-left: 10px;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 