.pagination{
    --bs-pagination-color: var(--color-Primary1);
    --bs-pagination-hover-color: var(--color-Primary1);
    --bs-pagination-focus-color: var(--color-Primary1);
    --bs-pagination-active-bg: var(--color-Primary1);
    --bs-pagination-active-border-color: var(--color-Primary1);
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    padding-top: 24px;
    flex-wrap: wrap;
}
.page-link {
    border-color: var(--color-Gray1);
    padding: 5px 10px;
}
.page-item {
    &.active {
        font-family: var(--font-Bold);
    }
}