/* ------------------------------------------------------------
    PLANEACIONES PRINT
------------------------------------------------------------ */

.print-plan-body {
     width: 700px !important;
     margin: 0 auto;
     padding: 20px;
     display: none;
     letter-spacing: 0;
     &.print-section {
          display: block;
     }
}
.print-plan__title {
     p {
          margin: 0;
     }
     h1 {
          font-size: 18px;
          line-height: 25px;
          color: var(--color-Secondary1);
     }
     .proyecto-nem {
          font-size: 25px;
          line-height: 35px;
     }
}
.print-plan__logo {
     width: 60px;
     margin-bottom: 16px;
     img {
          width: 100%;
     }
}
.print-plan__section {
     width: 100%;
     margin-top: 10px;
}
.print-section__item {
     width: 100%;
     margin-bottom: 10px;
     border-top: 1px solid var(--color-Secondary1);
     // border-bottom: 3px solid var(--color-Secondary1);
     padding: 15px 0;
}
.print-section__row  {
     margin-bottom: 15px;
     &.row-col--title {
          h5 {
               color: var(--color-Primary1);
          }
     }
     &.row-col--subtitle {
          h5 {
               color: var(--color-Fith1);
          }
     }
     &.section__row-subsection {
          background: #fff;
          padding: 16px 0;
     }
     &:last-child {
          margin-bottom: 0;
     }
}
.print-section__row__col {
     margin-bottom: 16px;
     &.row--division__inner-title {
          h5 {
               color: var(--color-Secondary3);
          }
     }
     
     &:only-child {
          margin-bottom: 0;
     }
     small {
          color: var(--color-Gray5);
          font-size: 12px;
          line-height: 20px;
     }
     h6,
     p {
          font-size: 14px;
          line-height: 20px;
     }
     h6 {
          color: var(--color-Base);
     }
     p {
          color: var(--color-Gray5);
     }
     ul {
          margin-top: 16px;
          h6 {
               color: var(--color-Fourth1);
          }
          li {
               font-size: 14px;
               margin-bottom: 6px;
               color: var(--color-Gray5);
               &:last-child {
                    margin-bottom: 0;
               }
          }
     }
     .print-section__url {
          color: var(--color-Primary1);
     }
}

.planning-nem-paco {
     display: block;
     margin: 0 auto;
     padding: 20px;
     letter-spacing: 0;
}

.planning-reformas {
     display: block;
     margin: 0 auto;
     margin-top: 100px;
     padding: 20px;
     letter-spacing: 0;

}

.nem-section__row__col {
     margin-bottom: 20px;
     h6,
     p {
          font-size: 15px;
          line-height: 25px;
     }
     h6 {
          color: var(--color-Fourth3);
     }
     p {
          color: var(--color-Gray5);
     }
     img {
          width: 7%;
     }
     a {
          margin-top: 25px;
     }
}
.print-subsection {
     background: var(--color-Gray8);
     padding: 16px;
}
.prin-section__session {
     border-top: 1px solid var(--color-Fith3);
     border-bottom: 1px solid var(--color-Fith3);
     margin-top: 16px;
     padding-top: 16px;
}
.row--division {
     border-top: 1px solid var(--color-Fith3);
     padding-top: 16px;
}
.print-sign {
     height: 100px;
     width: 200px;
     border-bottom: 1px solid var(--color-Gray7);
}
.row-col--thirdtitle {
     h6 {
          color: var(--color-Fourth1);
     }
}

/*
  ::::::: 
  PRINT MODE
  :::::::
*/


@media print {
     .print-plan-body {
          display: block;
          width: 700px;
          &.print-section {
               display: block !important;
          }
     }
   

} 