/* ------------------------------------------------------------
     Modal Reports
------------------------------------------------------------ */

.modal--reports {
     .modal__title-icon.modal-header {
          padding-bottom: 16px;
     }
     .modal-title {
          display: flex;
          align-items: center;
          h4 {
               margin: 0;
               font-family:  var(--font-Title);
          }
          .modal__title-icon-element {
               margin-right: 16px;
          }
          .modal__close {
               position: absolute;
               top: 10px;
               right: 20px;
          }
     }
     .modal__icon {
          width: 50px;
          margin-bottom: 0;
          margin-right: 8px;
     }
     .modal-body {
          text-align: center;
          background: var(--color-Gray8);
          h5 {
               margin-bottom: 16px;
          }
          p {
               margin-bottom: 8px;
          }
     }
     .modal-footer {
          border-top: 0;
          display:flex;
          padding-bottom: 24px;
          justify-content: center;
          
     }
     .modal-content {
          border-radius: 10px;
          box-shadow: 0 10px 30px rgb(0 0 0 / 20%);
          border: 0;
     }
     .modal__actions-inner {
          background: #fff;
          padding: 10px;
          .form-check {
               text-align: left;
          }
     }
}

.modal__title-icon-element {
     background: var(--color-Danger1);
     display: flex;
     align-items: center;
     justify-content: center;
     width: 30px;
     height: 30px;
     border-radius: 50%;
     span {
          color: #fff;
     }
}
.modal__close {
     width: 30px;
     height: 30px;
     background: transparent;
     border: 0;
     span {
          font-size: 32px;
     }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
    
}
   
/* Small */
@media (max-width:680px) {
    
}