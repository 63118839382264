/* ------------------------------------------------------------
     VIDEO PLAYER
------------------------------------------------------------ */
.video-player {
  width: 100%;
  height: 500px;
}


@media (max-width: 768px) {
  .video-player {
    height: 400px;
  }
}
@media (max-width: 680px) {
  .video-player {
    height: 250px;
  }
}