/* ------------------------------------------------------------
     BADGES
------------------------------------------------------------ */
.badge {
  &.bg-alert {
    background: var(--color-Warning1);
  }
}

.alert {
  &.alert--with-icon {
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      margin-right: 8px;
    }
  }
  &.alert--small {
    padding: 5px 10px;
    font-size: 12px;
  }
  &.alert-simple {
    background: var(--color-Gray3);
    border-radius: 10px;
    border-color: var(--color-Gray4);
    border-width: 3px;
  }
  &.alert-neutral {
    background: var(--color-Primary4);
    border-radius: 10px;
    border-color: var(--color-Primary2);
    border-width: 3px;
  }
  &.alert-password {
    background: var(--color-Primary4);
    border-radius: 10px;
    border-color: var(--color-Primary1);
    border-width: 3px;
    padding: 10px;
    p {
      line-height: 18px;
    }
  }
  &.alert-ready {
    background: var(--color-Success2);
    border-radius: 10px;
    border-color: var(--color-Success2);
    border-width: 3px; 
    p {
      display: flex;
      text-align: center;
      justify-content: center;
      span {
        margin-right: 4px;
        color: var(--color-Success1);
      }
    }
  }
  p,
  ul {
    margin-bottom: 8px;
    font-size: 14px;
    color: var(--color-Base);
    &:last-child {
      margin-bottom: 0;
    }
  }
}