/* ------------------------------------------------------------
     STEPPER
------------------------------------------------------------ */
.stepper {

  &.stepper--planning {
    a {
      display: block;
      &:hover {
        .stepper__span {
          border-color: var(--color-Six3);
        }
      }
    }
    .stepper__span {
      color: var(--color-Base);
      border: 2px solid transparent;
      transition: .4s all ease-in-out;
    }
    &[data-status="step-1"] {
      li {
        &:first-child {
          .stepper__span {
            background: var(--color-Fourth1);
            color: #fff;
          }
        }
      }
    }
    &[data-status="step-2"] {
      li {
        &:first-child,
        &:nth-child(2) {
          .stepper__span {
            background: var(--color-Fourth1);
            color: #fff;
          }
        }
      }
      ul {
        &:after {
          width: 25.66%;
        }
      }
    }
    &[data-status="step-3"] {
      li {
        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
          .stepper__span {
            background: var(--color-Fourth1);
            color: #fff;
          }
        }
      }
      ul {
        &:after {
          width: 50.32%;
        }
      }
    }
    &[data-status="step-4"] {
      li {
        &:first-child,
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          .stepper__span {
            background: var(--color-Fourth1);
            color: #fff;
          }
        }
      }
      ul {
        &:after {
          width: 72.98%;
        }
      }
    }
    &[data-status="step-5"] {
      li {
        &:first-child,
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          .stepper__span {
            background: var(--color-Fourth1);
            color: #fff;
          }
        }
      }
      ul {
        &:after {
          width: 98.64%;
        }
      }
    }
    &[data-status="step-6"] {
      li {
        .stepper__span {
          background: var(--color-Fourth1);
          color: #fff;
        }
      }
      ul {
        &:after {
          width: 98.33%;
        }
      }
    }
  }
  
  
}

/*
:::::::
Responsive
:::::::
*/

/* Small medium */
@media (max-width:768px) {

}

/* Small */
@media (max-width:680px) {
  .stepper {
    &.stepper--planning {
      ul {
        li {
          small {
            display: none;
          }
        }
      }
    }
   
  }
}