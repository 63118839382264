/* ------------------------------------------------------------
    SMALL
------------------------------------------------------------ */
@media (max-width:680px) {
    h1,
    .h1 {
        font-size: 36px;
        line-height: 40px;
    }
    h2,
    .h2 {
        font-size: 24px;
        line-height: 30px;
    }
    h3,
    .h3 {
        font-size: 21px;
        line-height: 35px;
    }
    .single-title {
        font-size: 21px;
        line-height: 35px;
    }
   
    .module__title {
        text-align: left;
        margin-bottom: 0;
   }
   .module {
        padding: 30px 0;
    }
    .module-small {
        padding-top: 30px;
        padding-bottom: 30px;
   }
    .col--mobile {
        padding-left: 0;
        padding-right: 0;
    }
    .container--mobile {
        padding-left: 0;
        padding-right: 0;
        .module__action {
            display: none;
        }
        .row {
            margin: 0;
        }
        .module__title {
            padding-left: 15px;
            padding-right: 15px;
           
            &.title--action {
                justify-content: space-between;
                h2 {
                    width: 80%;
                }
                a {
                     display: block;
                }
           }
       }
    }
}
@media (max-width:580px) {
   
}
@media (max-width:480px) {
  
}
@media (max-width:380px) {
    
}