/* ------------------------------------------------------------
     SWAL
------------------------------------------------------------ */
.swal2-popup {
  border-radius: 10px;
  box-shadow: 0 10px 30px rgb(0 0 0 / 20%);
}
/* Swal buttons */
.swal2-styled.swal2-confirm {
  background: var(--color-Primary1);
}
.swal2-styled.swal2-cancel,
.swal2-styled.swal2-confirm  {
  border-radius: 30px;
  font-weight: 600;
  min-width: 180px;
}
.swal2-styled.swal2-cancel {
  background: transparent;
  border: 2px solid var(--color-Primary1);
  color: var(--color-Primary1);
}
.swal2-input {
  border-radius: 10px;
  border-color: var(--color-Gray7);
  box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
  color: var(--color-Base);
  height: 40px;
}