/* ------------------------------------------------------------
     ADVICE
------------------------------------------------------------ */
.advice-brand {
  background: var(--color-Third1);
  padding: 20px 0;
}
.advice-brand-survey {
  background: var(--color-Secondary1);
  padding: 25px 0;
}
.advice-brand__col {
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  article {
    text-align: center;
    h6 {
      color: var(--color-Secondary1);
      font-family: var(--font-Regular);
      font-size: 21px;
      line-height: 26px;
    }
    p {
      font-size: 18px;
      color: var(--color-Primary6);
    }
  }
  figure {
    margin: 0;
    position: relative;
    z-index: 1;
    width: 98px;
    top: -1px;
    pointer-events: none;
    img {
      width: 100%;
    }
  }
}
.advice-brand-survey__col {
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  article {
    text-align: center;
    h6 {
      color: #fff;
      font-family: var(--font-Regular);
      font-size: 24px;
      line-height: 26px;
    }
    p {
      font-size: 18px;
      color: var(--color-Primary6);
    }
    .yellow {
      color: var(--color-Third1);
      font-family: var(--font-Regular);
      font-size: 21px;
      line-height: 26px;
    }
  }
  figure {
    margin: 0;
    position: relative;
    z-index: 0;
    width: 140px;
    top: 7px;
    pointer-events: none;
    img {
      width: 100%;
    }
  }
}

.advice-buttons {
  margin: auto 20px;
  .advice-btn1 {
    color: var(--color-Secondary1);
    background: var(--color-Third1);
    margin-right: 10px;
  }
  .advice-btn1:hover,
  .advice-btn2:hover {
    color: var(--color-Secondary1);
    background: var(--color-Third3);
  }
  .advice-btn2 {
    color: var(--color-Secondary1);
    background: var(--color-Gray2);
  }
  .advice-btn3 {
    color: var(--color-Gray2);
    background: var(--color-Secondary1);
  }
  p {
    font-size: 10px;
    text-align: end;
    margin-top: 5px;
    color: #fff;
  }
}

/* option 2 */
.advice-brand-survey__option2 {
  background: var(--color-Blue3);
  width: 100%;
  height: 89px;
  overflow: hidden;
  .advice-brand-survey__col {
    a {
      height: 89px;
      > * {
        margin: 0 10px;
      }
    }
    figure {
      width: 163px;
      top: 0;
      height: 100%;
      img {
        height: 100%;
        object-fit: contain;
        object-position: bottom;
      }
    }
  }
}
article.advice-brand-survey__option2-title {
  color: var(--color-Secondary1);
  h6 {
    color: var(--color-Secondary1);
    font-family: var(--font-Title);
    span {
      display: block;
    }
  }
  
}

/* WArti */
.advice-brand-survey__warti {
  background: var(--color-Blue1);
  width: 100%;
  height: 89px;
  overflow: hidden;
  .advice-brand-survey__col {
    a {
      height: 89px;
      > * {
        margin: 0 15px;
      }
    }
    figure {
      width: 220px;
      top: 15px;
      margin-left: -45px !important;
      img {
        height: 100%;
        object-fit: contain;
        object-position: bottom;
      }
    }
  }
}
article.advice-brand-survey__warti-title {
  color: var(--color-Secondary1);
  h6 {
    font-size: 22px;
    color: var(--color-Secondary1);
    font-family: var(--font-Title);
    span {
      display: block;
    }
  }
}


@media (max-width: 992px) {
  .advice-brand__col article {
    h6 {
      font-size: 16px;
      line-height: 20px;
    }
    p {
      font-size: 14px;
    }
  }
  .advice-brand-survey {
    padding: 15px 0;
  }
  .advice-buttons {
    display: grid;
    .btn--type1 {
      min-width: 100px;
      font-size: 12px;
      padding: 1px;
    }
    .advice-btn1 {
      margin: 0 0 5px 0;
    }
    p {
      margin-top: 0px;
    }
  }
  .advice-brand-survey__col {
    figure {
      width: 110px;
      top: 7px;
    }
  }
  .advice-brand-survey__option2 {
    height: 90px;
    .advice-brand-survey__col a {
      height: 90px;
      > * {
        margin: 0 16px;
      }
      .advice-buttons .btn--type1 {
        min-width: 160px;
        font-size: 12px;
        padding: 5px;
      }
    }
  }
  .advice-brand-survey__warti {
    height: 90px;
    .advice-brand-survey__col a {
      height: 90px;
      > * {
        margin: 0 8px;
      }
      .advice-buttons .advice-btn3 {
        min-width: 160px;
        font-size: 14px;
        padding: 5px;
      }
      figure {
        top: 18px;
      }
    }
  }
  article.advice-brand-survey__warti-title {
    h6 {
      font-size: 15px;
    }
  }
}
@media (max-width: 768px) {
  .advice-brand__col article {
    h6 {
      font-size: 14px;
      line-height: 18px;
    }
    p {
      font-size: 12px;
    }
  }
  .advice-brand-survey__option2 {
    .advice-brand-survey__col {
      figure {
        width: 123px;
      }
      a > * {
        margin: 0 10px;
      }
    }
  }
  article.advice-brand-survey__option2-title h6 {
    font-size: 14px;
    line-height: 17px;
  }
  .advice-brand-survey__warti {
    height: 86px;
    .advice-brand-survey__col a {
      height: 86px;
      > * {
        margin: 0 8px;
      }
      .advice-buttons .advice-btn3 {
        min-width: 100px;
        font-size: 12px;
        padding: 5px;
      }
      figure {
        width: 170px;
        top: 20px;
        margin-left: -35px !important;
      }
    }
  }
  article.advice-brand-survey__warti-title {
    h6 {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
@media (max-width: 680px) {
  .advice-brand__col {
    article {
      a {
        height: 30px;
      }
      h6 {
        font-size: 14px;
        line-height: 18px;
      }
      p {
        font-size: 12px;
        line-height: 15px;
      }
    }
    figure {
      display: none;
    }
  }
  .advice-brand-survey {
    padding: 17px 0;
  }
  .advice-brand-survey__col {
    article {
      h6 {
        font-size: 18px;
      }
      .yellow {
        font-size: 18px;
      }
    }
    figure {
      display: none;
    }
  }
  .advice-buttons {
    p {
      font-size: 8px;
    }
  }
  article.advice-brand-survey__option2-title h6
  article.advice-brand-survey__warti-title h6  {
    font-size: 15px;
    
  }
  .advice-brand-survey__option2 {
    height: 80px;
    .advice-brand-survey__col a {
      height: 80px;
      > * {
        margin: 0 6px;
      }
      .advice-buttons .btn--type1 {
        min-width: 140px;
        padding: 5px 2px;
      }
    }
  }
}
@media (max-width: 350px) {
  .advice-brand-survey {
    padding: 10px 0;
  }
  .advice-brand-survey__col {
    article {
      h6 {
        font-size: 13px;
      }
      .yellow {
        font-size: 13px;
      }
    }
  }
}