/* ------------------------------------------------------------
     SEPARATOR
------------------------------------------------------------ */
.separator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0;
  &:before,
  &:after {
    content: '';
    width: 40%;
    height: 1px;
    background: var(--color-Gray4);
    display: block;
  }
  p {
    margin-bottom: 0;
  }
}