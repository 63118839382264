/* ------------------------------------------------------------
     BUTTON TYPE 1
------------------------------------------------------------ */
.btn {
  &.btn--type1 {
    background: var(--color-Primary1);
    font-weight: 600;
    color: #fff;
    border-radius: 20px;
    min-width: 160px;
    transition: .4s all ease-in-out;
    border: 0;
    display: inline-flex;
    justify-content: center;
    &.advice-btn2 {
      background: var(--color-Fourth4);
      color: #fff;
      box-shadow: 0px 4px 0px 0px #012480;
      &:hover {
        background: var(--color-Fith2);
        color: #fff;
      }
    }
    .icon {
      margin-left: 8px;
      color: #fff;
    }
    &:hover {
      background: var(--color-Primary2);
      color: #fff;
      border: 0;
    }
   
  }
}