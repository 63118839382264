/* ------------------------------------------------------------
    DASHBOARD
------------------------------------------------------------ */
.container-materials__cta {
  justify-content: right;
  display: flex;
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
  .container-materials__cta {
    order: 2;
  }
  .container-materials__top-row {
    align-items: center;
    margin-bottom: 24px;
    .module__title {
      margin-bottom: 0;
      justify-content: flex-start !important;
      h2 {
        margin-bottom: 0;
      }
    }
  }
}
   
/* Small */
@media (max-width:680px) {
  .container-materials__top-row {
    .module__title {
      h2 {
        font-size: 21px;
      }
    }
  }
     
}