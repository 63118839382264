/* ------------------------------------------------------------
    SESSION 
------------------------------------------------------------ */
.result-block__row {
  h4 {
    margin-bottom: 0;
  }
}
.options-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-Gray8);
  padding: 10px;
  border-radius: 20px;
  margin: 24px auto 0 auto;
  max-width: 900px;
  width: 100%;
  > div {
    margin-left: 16px;
  }
}
.options-bar__add {
  order: 2;
}
.options-bar__delete {
  order: 1;
}
.options-bar__continue {
  order: 3;
}
.option--simple {
  color: var(--color-Primary1);
  border-radius: 50px;
  text-decoration: underline;
  border: 0;
  &:hover {
    text-decoration: none;
    color: var(--color-Primary2);
    background: var(--color-Gray1);
  }
}
.option--delete {
  color: var(--color-Danger1);
  display: flex;
  align-items: center;
  border-radius: 50px;
  border: 0;
  &:before {
    content: '';
    background: url(../../../../public/assets/images/icon/delete.png) no-repeat;
    display: block;
    width: 15px;
    height: 15px;
    background-size: contain;
    margin-right: 10px;
  }
  &:hover {
    color: var(--color-Danger1);
    background:  var(--color-Danger2);
  }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:992px) {
  .result-block__stick {
    position: relative;
    top: 0;

  }
  .options-bar {
    flex-wrap: wrap;
  }
  .options-bar__col {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
   
  }
  .options-bar__add {
    order: 2;
  }
  .options-bar__delete {
    order: 3;
    margin-bottom: 0;
  }
  .options-bar__continue {
    order: 1;
  }
}
   
/* Small */
@media (max-width:680px) {
  .options-bar {
    border-radius: 0;
  }
     
}