/* ------------------------------------------------------------
     ACCORDION
------------------------------------------------------------ */
.simple-accordion {
  .accordion-item {
    background: var(--color-Gray3);
    margin-bottom: 24px;
    border-radius: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .accordion-header {
      border-radius: 10px;
      button {
        border-radius: 10px;
        padding: 10px;
        font-weight: 600;
        align-items: center;
        display: flex;
        justify-content: space-between;
        font-family:  var(--font-Bold);
        &[aria-expanded="true"] {
          border-radius: 10px 10px 0 0;
          color: var(--color-Secondary1);
          background: var(--color-Fith3);
          
        }
        &.collapsed {
          span {
            transform: rotate(0deg);
          }
        }
        span {
          color: var(--color-Secondary1);
          font-size: 34px;
          transform: rotate(180deg);
        }
      }
    }
    .accordion-body {
      padding: 30px;
    }
  }
}