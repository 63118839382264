/* ------------------------------------------------------------
     DATEPICKER
------------------------------------------------------------ */
body {
     .rs-picker {
          &.rs-picker-has-value .rs-picker-toggle{
               border-width: 2px;
               border-color: var(--color-Success1);
               box-shadow: 0 0 0 0.25rem rgb(103 191 120 / 15%);
          }
     }
     .datepicker {
          .MuiOutlinedInput-notchedOutline {
               border-radius: 10px;
               border-color: var(--color-Gray7);
               box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
          }
          .MuiOutlinedInput-input {
               padding: 0 14px;
               position: relative;
               top: -3px;
               height: 38px;
          }
          .MuiFormControl-root {
               padding-top: 3px;
          }
          input {
               box-shadow: initial;
          }
          legend {
               display: none;
          }
          
     }
     .MuiPaper-root {
          button {
               &.Mui-selected {
                    background-color: var(--color-Secondary2);
                    &:hover {
                         background-color: var(--color-Gray7);
                    }
               }
          }
     }
}