/* ------------------------------------------------------------
     ARTICHAT
------------------------------------------------------------ */
.arti-chat-widget {
  background: var(--color-Gray8);
  width: 100%;
  border-radius: 10px;
  .accordion-header {
    button {
      background: var(--color-Fith7);
      padding: 0 10px;
      color: var(--color-Fith1);
      span {
        color: var(--color-Fith1);
      }
    }
  }
  .accordion,
  .accordion-item {
    background: transparent;
  }
}
.arti-chat__add {
  width: 100%;
  padding: 10px 15px;
  figure {
    width: 100%;
    height: 100px;
    margin: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
.arti-chat__body {
  padding: 15px;
}
.arti-chat__inner {
  border-radius: 10px;
  background: #fff;
  padding: 10px;
  width: 100%;
}
.arti-chat__header {
  width: 100%;
  margin-bottom: 16px;
  figure {
    width: 100%;
    height: 50px;
    margin: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
.arti-chat__message {
  padding: 10px 0;
  width: 100%;
  max-height: 350px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.message-row {
  display: flex;
  margin-bottom: 16px;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
  &.message--type1 {
    .message-row__avatar {
      border-color: var(--color-Pink100);
    }
    .message-row__message {
      background: var(--color-Pink100);
      * {
        color: #fff;
      }
      p {
        white-space: pre-line;
      }
    }
  }
  &.message--type2 {
    .message-row__avatar {
      border-color: var(--color-Blue1);
    }
    .message-row__message {
      background: var(--color-Blue1);
      * {
        color: var(--color-Fourth1);
      }
      p {
        white-space: pre-line;
      }
    }
  }
  &.message--type3 {
    .message-row__avatar {
      border-color: var(--color-Third1);
    }
    .message-row__message {
      background: var(--color-Third1);
      * {
        color: var(--color-Fourth1);
      }
      p {
        white-space: pre-line;
      }
    }
  }
  &.message--type4 {
    .message-row__avatar {
      border-color: var(--color-Primary6);
    }
    .message-row__message {
      background: var(--color-Primary6);
      * {
        color: #fff;
      }
      p {
        white-space: pre-line;
      }
    }
  }
  &.message--right {
    justify-content: flex-end;
    .message-row__avatar {
      border-color: var(--color-Fourth1);
    }
    .message-row__message {
      height: 100%;
      width: auto;
      max-width: 70%;
      background: var(--color-Fourth1);
      border-radius: 10px 0 10px 10px;
      margin-left: 0;
      margin-right: 16px;
      * {
        color: #fff;
      }
      p {
        white-space: pre-line;
      }
    }
  }
  &.message--think {
    .message-row__message {
      height: 100%;
      width: auto;
      max-width: 25%;
      img { 
        width: 45px;
      }
    }
  }
  
}
.message-row__avatar {
  width: 50px;
  height: 50px;
  border: 3px solid var(--color-Gray8);
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.message-row__message {
  background: var(--color-Gray8);
  border-radius: 0 10px 10px 10px;
  padding: 5px 10px;
  width: calc(100% - 66px);
  margin-left: 16px;
  * {
    font-size: 14px;
    color: var(--color-Fourth1);
  }
  p {
    white-space: pre-line;
  }
}
.arti-chat__bottom {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  width: 100%;
  margin-top: 8px;
  input {
    border: 0;
    background: #fff;
    border-radius: 10px;
    width: calc(100% - 56px);
    box-shadow: initial;
  }
  button {
    background: var(--color-Fourth1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    margin-left: 16px;
    cursor: pointer;
    transition: .4s all ease-in-out;
    &:hover {
      background: var(--color-Fourth3);
    }
    span {
      color: #fff;
    }
  }
}
.arti-chat__power {
  width: 100%;
  border-top: 2px solid var(--color-Gray7);
  margin-top: 8px;
  padding: 8px 10px;
}
.arti-chat__toggle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-Fourth1);
  &.active {
    .icon {
      transform: rotate(-180deg);
    }
  }
  .icon {
    color: var(--color-Fourth1);
  }
}
.power-assets {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8px 0 0 0;
  overflow: hidden;
  height: 50px;
  &.active {
    height: 110px;
  }
  .btn {
    width: 48%;
    margin-bottom: 8px;
  }
}
.arti-chat__legal {
  width: 100%;
  margin-top: 8px;
  text-align: center;
  p {
    font-size: 12px;
    color: var(--color-Fourth1);
    a {
      color: var(--color-Fourth1);
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.arti-chat-wrapp {
  width: 100%;
}

@media (max-width: 768px) {
  
}
@media (max-width: 680px) {
  .arti-chat-widget {
   
    .accordion-header button {
      padding: 10px;
      .icon {
        top: 11px;
      }
    }
  }
  
}