/* ------------------------------------------------------------
    INTRODUCCION 
------------------------------------------------------------ */
.session-tab__alert {
  padding-top: 32px;
}


/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
  .session-tab__alert {
    padding-top: 0;
  }
}
   
/* Small */
@media (max-width:680px) {
     
     
}