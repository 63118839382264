/* ------------------------------------------------------------
     BUTTON TYPE OUTLINE
------------------------------------------------------------ */
.btn {
  &.btn--type-outline {
    background: #fff;
    border: 2px solid var(--color-Primary1);
    font-weight: 600;
    color: var(--color-Primary1);
    border-radius: 50px;
    height: 50px;
    width: 250px;
    transition: .4s all ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 17px;
    &:hover {
      background: var(--color-Primary3);
      border-color: var(--color-Primary3);
      color: #fff;
    }
    &.btn--auto {
      width: auto;
      padding-left: 30px;
      padding-right: 30px;
    }
    &.btn--delete {
      border-color: var(--color-Danger1);
      color: var(--color-Danger1);
      &:before {
        content: '';
        background: url(../../../../public/assets/images/icon/delete.png) no-repeat;
        display: block;
        width: 15px;
        height: 15px;
        background-size: contain;
        margin-right: 10px;
      }
      &:hover {
        background: var(--color-Danger2);
      }
    }
    span {
      font-size: 18px;
      margin-left: 4px;
    }
  }
}