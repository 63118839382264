/* ------------------------------------------------------------
     Modal Toolip
------------------------------------------------------------ */
.modal-tooltip {
     position: fixed;
     bottom: 30px;
     right: 30px;
     z-index: 2;
     background: var(--color-Gray3);
     width: 400px;
     box-shadow: 0 10px 10px rgb(0 0 0 / 30%);
     border-radius: 10px;
     .accordion-button {
          color: var(--color-Secondary1);
          background-color: var(--color-Fith4);
          border-radius: 10px 10px 0 0;
          padding: 0 10px;
          border-color: var(--bs-accordion-btn-focus-border-color);
          box-shadow: var(--bs-accordion-btn-focus-box-shadow);
          &[aria-expanded="true"] {
               border-color: transparent;
               box-shadow: initial;
          }
          .icon {
               color: var(--color-Secondary1);
          }
     }
     .accordion-body {
          padding: 10px;
          font-size: 14px;
          li {
               margin-bottom: 4px;
               &:last-child {
                    margin-bottom: 0;
               }
          }
     }
}
.modal-tooltip__header {
     display: flex;
     align-items: center;
     font-family: var(--font-Bold);
     span {
          margin-right: 8px;
          font-size: 18px;
     }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
    
}
   
/* Small */
@media (max-width:680px) {
     .modal-tooltip { 
          width: 90%;
          right: 5%;
          .accordion-button {
               padding: 10px 10px 6px 10px;
          }
     }
}