/* ------------------------------------------------------------
     CHECKBOX
------------------------------------------------------------ */
body {
     input {
          &[type="checkbox"],
          &.form-check-focus {
               width: 1.2em;
               height: 1.2em;
               margin-right: 8px;
               cursor: pointer;
               &:checked {
                    background-color: var(--color-Primary1);
                     border-color: var(--color-Primary1);
               }
               &:focus {
                    border-color: var(--color-Primary1);
                    box-shadow: 0 0 0 0.25rem rgb(96 129 226 / 8%);
               }
          }
     }
}
.form-check {
     label {
          font-size: 14px;
          font-weight: 600;
          padding-top: 2px;
          cursor: pointer;
          width: 95%;
     }
}