/* ------------------------------------------------------------
     FOOTER
------------------------------------------------------------ */
.footer {
  background: var(--color-Secondary1);
  padding: 60px 0;
}
.footer__row {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}
.footer__logo {
  width: 124px;
  display: block;
  figure {
    &,
    img {
      width: 100%;
    }
  }
  
}
.footer__col-social {
  display: flex;
  justify-content: flex-end;
}
.social-list {
  margin: 0;
  list-style: none;
  padding: 0;
  text-align: center;
  width: 124px;
  li {
    display: inline-block;
    padding: 0 12px;
    a {
      display: block;
      color: #fff;
      transition: .4s all ease-in-out;
      &:hover {
        color: var(--color-Gray1);
      }
      i {
        font-size: 18px;
        
      }
    }
  }
}
.footer__nav {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    width: 100%;
    display: flex;
    margin-bottom: 8px;
    &:last-child {
      &:after {
        display: none;
      }
    }
    a {
      display: block;
      color: #fff;
      transition: .4s all ease-in-out;
      text-decoration: none;
      font-size: 14px;
      font-weight: 300;
      &:hover {
        color: var(--color-Primary1);
      }
     
    }
  }
}
.footer__col-links {
  display: flex;
  justify-content: flex-end;
}
.footer__col-copy {
  p {
    font-weight: 600;
    color: #fff;
  }
}
.footer__tool {
  display: flex;
  flex-wrap: wrap;
  padding-top: 26px;
  margin-bottom: 32px;
  justify-content: flex-end;
  p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 8px;
  }

}
.footer__privacy {
  text-align: right;
  * {
    color: #fff;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
}

.footer__comparte {
  padding-top: 26px;
  text-align: right;
  * {
    color: #fff;
  }
  a {
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Large */
@media (max-width:1200px) {
 
}

/* Medium */
@media (max-width: 980px) {
  .footer {
    padding: 40px 0;
  }
  .footer__tool {
    justify-content: flex-start;
    padding-top: 24px;
    margin-bottom: 18px;
  }
  .footer__col-logo {
    margin-bottom: 24px;
  }
  .footer__privacy {
    text-align: left;
  }
  .footer__comparte {
    text-align: left;
  }
}
@media (max-width:768px) {
 
}

/* Small */
@media (max-width:680px) {
  .footer__logo {
    width: 94px;
  }
  .social-list li {
    text-align: left;
    padding-left: 0;
    padding-right: 24px;
  }
  .footer__privacy * {
    font-size: 14px;
  }
}