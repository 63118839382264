/* ------------------------------------------------------------
     CAROUSEL
------------------------------------------------------------ */
.carousel-main {
  width: 100%;
  margin: 60px 0 60px 0;
  .slick-dots {
    list-style: none;
    padding: 0;
    width: 300px;
    margin: 40px auto;
    display: flex !important;
    justify-content: center;
    li {
      display: inline-block;
      width: 20%;
      height: 6px;
      padding: 0 4px;
      transition: .4s all ease-in-out;
      button {
        transition: .4s all ease-in-out;
        width: 100%;
        border-radius: 20px;
        padding: 0;
        background: var(--color-Gray4);
        border: 0;
        position: relative;
        text-indent: -99999px;
        height: 6px;
      }
      &.slick-active { 
        width: 50%;
        button {
          
          background: var(--color-Primary1);
        }
      }
      &:hover {
        button {
          background: var(--color-Gray7);
        }
      }
    }
  }
}


/*
  :::::::
  Responsive
  :::::::
*/


/* Small */
@media (max-width:680px) {
  .carousel-main {
    margin: 40px 0 40px 0;
  }
}