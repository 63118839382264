/* ------------------------------------------------------------
     LIKE
------------------------------------------------------------ */
.check-like {
  position: relative;
  input {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &:checked {
      & + div {
        span {
          &.check__inactive {
           opacity: 0;
          }
          &.check__active {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
  }
}
.check-like__change {
  display: flex;
  align-items: center;
  figure {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    margin-bottom: 0;
    span {
      position: absolute;
      top: 2px;
      left: 0;
      font-size: 22px;
      opacity: 1;
      transition: .4s all ease-in-out;
      &.check__inactive {
        color: var(--color-Gray7);
      }
      &.check__active {
        opacity: 0;
        transform: scale(0);
        color: var(--color-Seven2);
      }
    }
  }
  p {
    margin: 0;
    font-size: 14px;
  }
}