/* ------------------------------------------------------------
    PLANNINGS
------------------------------------------------------------ */

.planning-desc {
  margin-bottom: 25px;
}

.banner-paco {
  display: flex;
  justify-content: center;
  margin: -35px 0 40px 0;
  img {
    width: 100%;
    border-radius: 1rem;
  }
}

.select-proyectos {
  margin-bottom: 30px;
  .btn {
    margin: 0 10px 10px 0;
    letter-spacing: 0.5px;
  }
}
.text-intro {
  font-size: 17px;
}
/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:992px) {
 
}
   
/* Small */
@media (max-width:680px) {
  .banner-paco {
    margin: 0px 0 30px 0;
  }
     
}