/* ------------------------------------------------------------
     SWITCH
------------------------------------------------------------ */
.form-switch {
     margin-top: 8px;
     .form-check-input {
          border: 1px solid var(--color-Primary1);
          width: 45px !important;
          height: 22px;
          background-color: var(--color-Primary5);
     }
}