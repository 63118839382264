/* ------------------------------------------------------------
     MODAL
------------------------------------------------------------ */
.modal {
  &.modal--info {
    &.info--session {
      .modal-footer,
      .modal-content {
        padding: 0;
      }
    }
    &.modal--secondary {
      background: rgba(0,0,0,0.3);
      .modal--secondary {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 700px;
      }
    }
    .modal-content {
      border-radius: 10px;
      box-shadow: 0 10px 30px rgb(0 0 0 / 20%);
      border: 0;
      padding: 20px;
    }
    .modal-header {
      position: relative;
      padding: 32px 0 8px 0;
      .btn-close {
        position: absolute;
        right: 20px;
        top: 20px;
      }
      .modal-title {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        span {
          color: var(--color-Gray7);
          font-size: 60px;
        } 
        p {
          width: 100%;
          text-align: center;
          font-family: var(--font-Bold);
          color: var(--color-Primary1);
          font-size: 16px;
        }
      }
    }
    .modal-body {
      padding: 0 20px 20px 20px;
      text-align: center;
     
      h4 {
        color: var(--color-Primary6);
        margin-bottom: 32px;
      }
    }
    .modal-footer {
      justify-content: center;
      border: 0;
    }
  }
  &.modal--arti {
    .modal__close {
      position: absolute;
      top: -20px;
      right: -20px;
      z-index: 1;
    }
    .modal-content {
      background: var(--color-Gray8);
    }
    .modal-body,
    .moda-header {
      padding: 0;
    }
  }
}
.modal-info__actions {
  padding: 24px 0;
  > div {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.modal-info__bottom {
  display: flex;
  justify-content: center;
  background: var(--color-Gray8);
  border-radius: 0 0 10px 10px;
  margin: 0;
  padding: 16px 0;
  .btn {
    border: 0;
    text-decoration: underline;
    font-family: var(--font-Bold);
    color: var(--color-Danger1);
    &:hover {
      text-decoration: none;
      border: 0;
    }
  }
}

@media (max-width: 680px) {
  .modal.modal--arti .modal__close {
    top: 4px;
    right: 4px;
  }
}