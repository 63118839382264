/* ------------------------------------------------------------
     HEADER
------------------------------------------------------------ */
header {
  background: var(--color-Secondary1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: .4s all ease-in-out;
  font-size: 18px;
  &[data-class="false"] {
    box-shadow: 0 0 10px rgba(0, 0, 0,.2);
    .header__search-bar {
      transform: translateY(-100%);
    }
  }
  .header__top-row {
    position: relative;
    z-index: 1;
    background: var(--color-Secondary1);
  }

}
.header__row {
  align-items: center;
}
.header__logo {
  
  max-width: 160px;
  display: block;
  figure {
    margin: 0;
    &,
    img {
      width: 100%;
    }
    img {
      height: 60px;
      object-position: left;
      object-fit: contain;
    }
  }
}
.header__col-mobile {
  display: none;
}

/* main nav */
.main-nav {
  ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
      padding: 24px 0;
      height: 90px;
      display: flex;
      margin-left: 18px;
      align-items: center;
      position: relative;
      &:hover {
        ol {
          opacity: 1;
          pointer-events: initial;
        }
      }
      &.main-nav__separator {
        border-left: 1px solid var(--color-Primary3);
        padding-left: 24px;
      }
      &.main-nav__extras {
        ol {
          left: initial;
          right: 0;
        }
      }
     
      a {
        color: #fff;
        text-decoration: none;
        font-weight: 500;
        transition: .4s all ease-in-out;
        font-family: var(--font-Bold);
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover,
        &.active {
          color: var(--color-Third1);
        }
        &.main-nav__link {
          text-decoration: underline;
          &.nav-link__profile {
            display: flex;
            align-items: center;
            text-decoration: none;
            transition: .4s all ease-in-out;
            background: var(--color-Primary6);
            border-radius: 20px;
            padding: 5px 20px;
            &:hover {
              opacity: .6;
            }
              span {
                margin-right: 8px;
              }
          }
          &:hover {
            text-decoration: none;
          }
        }
        .icon {
          font-size: 21px;
          position: relative;
          top: 2px;
          margin-left: 4px;
        }
      }
      ol {
        position: absolute;
        top: 90px;
        left: 0;
        background: var(--color-Primary6); 
        padding: 0;
        margin: 0;
        opacity: 0;
        pointer-events: none;
        width: 220px;
        border-radius: 0 0 20px 20px;
        li {
          padding: 10px 15px;
          margin: 0;
          height: auto;
         
          &.main-nav__logout {
            padding: 0;
            a {
              text-align: center;
              display: block;
              width: 100%;
              border-radius: 0 0 20px 20px;
              background: var(--color-Primary1);
              text-decoration: none;
              padding: 10px;
            }
          }
          a {
            display: block;
            padding: 0;
          }
        }
      }
      .opinion{
        color: #fff;
      }
    }
  }
}
.main-nav--link-help {
  .icon {
    // color: var(--color-Fith4);
    position: relative;
    top: 3px;
    cursor: pointer;
  }
}
.icon--text{
  display: none;
}


/* open nav */
#open-nav {
  height: 32px;
  width: 42px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  border: 0;
  padding: 0;
  background: transparent;
  position: relative;
  z-index: 1;
  &.active {
       small {
         background: #fff !important;
            &:nth-child(1) {
                 transform: rotate(-45deg);
            }
            &:nth-child(2) {
                 transform: scaleY(0);
            }
            &:nth-child(3) {
                 transform: rotate(45deg);
            }
       }
  }
  small {
       display: block;
       height: 3px;
       width: 100%;
       border-radius: 10px;
       background: #fff;
       &:nth-child(1) {
            transform-origin: 93% 0%;
            transition: transform 0.4s ease-in-out;
       }
       &:nth-child(2) {
            transition: transform 0.2s ease-in-out;
            width: 70%;
       }
       &:nth-child(3) {
            transform-origin: 101% 0%;
            transition: transform 0.4s ease-in-out;
       }
  }
}


/* Header search bar */
.header__search-bar {
  background: var(--color-Secondary1);
  padding: 10px 0;
  position: absolute;
  bottom: -60px;
  width: 100%;
  left: 0;
  transition: .4s all ease-in-out;
}
.header__search-bar-item {
  position: relative;
  font-size: 16px;
  
  input {
    background: var(--color-Gray1);
    border: 0;
    border-radius: 40px;
    width: 100%;
    padding: 0 20px;
  }
  a {
    position: absolute;
    right: 20px;
    top: 7px;
    color: var(--color-Gray5);
  }
}
.header__arti {
  background: transparent;
  margin-left: 15px;
  width: 140px;
  border: 0;
  transition: .4s all ease-in-out;
  transform: scale(1);
  &:hover {
    transform: scale(1.1);
  }
  img {
    width: 100%;
  }
}
.header__gif {
  display: none;
}

/*
  :::::::
  Responsive
  :::::::
*/

/* xLarge */
@media (max-width:1400px) {
  .main-nav {
    &.main--login {
      a {
        font-size: 17px;
      }
    }
    ul li {
      margin-left: 13px;
    }
  }
}

/* Large */
@media (max-width:1400px) {
  .main-nav ul li a {
    font-size: 17px;
  }
}
@media (max-width:1200px) {
  .header__logo {
    width: 130px;
  }
  .header {
    &[data-class="false"] {
    }
    #open-nav {
      small {
        background: var(--color-Secondary5);
      }
    }
  }
  .header__top-row {
    padding: 10px 0;
  }
  .main-nav ul li a {
    font-size: 17px;
  }
  #open-nav {
    display: flex;
  }
  .header__col-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .header__col-left {
    display: flex;
    justify-content: flex-start;
  }
  .header__col-mobile {
    display: flex;
  }
  .main-nav {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    background: rgb(0 57 128 / 39%);
    display: flex;
    opacity: 0;
    transition: .4s all ease-in-out;
    justify-content: flex-end;
    .header__arti {
      display: none;
    }
    ul {
      background: var(--color-Fourth1);
      width: 50%;
      display: block;
      padding: 30px 0;
      justify-content: flex-end;
      li {
        width: 100%;
        padding: 0 30px;
        height: auto;
        flex-wrap: wrap;
        &.main-nav__separator {
          border-top: 1px solid var(--color-Fith1);
          border-left: 0;
          padding-left: 30px;
          padding-top: 10px;
          padding-bottom: 10px;
          margin-top: 16px;
        }
       
        &.main-nav__extras {
          margin-bottom: 16px;
          border-top: 1px solid var(--color-Fith1);
          &:last-child {
            margin-bottom: 0;
          }
          a {
            &.link--close-sesion {
              font-weight: 600;
              color: #fff;
              border-radius: 20px;
              min-width: 160px;
              transition: 0.4s all ease-in-out;
              background: transparent;
              border: 2px solid #fff;
              padding: 8px 0;
              text-align: center;
              text-decoration: none;
              justify-content: center;
            }
          }
          ol {
            padding: 0;
           
          }
        }
        a {
          color: #fff;
          font-weight: 300;
          padding: 16px 0;
          &.active {
            font-weight: 600;
          }
          &.btn {
            padding: 8px 0;
          }
          &.main-nav__login {
            font-weight: 600;
            color: #fff;
            border-radius: 20px;
            min-width: 160px;
            transition: 0.4s all ease-in-out;
            background: transparent;
            border: 2px solid #fff;
            padding: 8px 0;
            text-align: center;
            justify-content: center;
            text-decoration: none;
          }
          &.main-nav__link.nav-link__profile {
            background: transparent;
            color: #fff;
            padding: 10px 0;
            .icon--profile {
              background: transparent;
              padding: 0;
            }
           
          }
          .icon {
            display: none;
          }
        }
        ol {
          position: relative;
          top: 0;
          opacity: 1;
          width: 100%;
          padding: 0 0 0 16px;
          background: var(--color-Fith1);
         
        }
      }
    }
    &[data-action="active"] {
      right: 0;
      opacity: 1;
    }
  }
  .nav-link__profile {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--color-Secondary5);
    transition: .4s all ease-in-out;
    &:hover {
      opacity: .6;
    }
    span {
      color: var(--color-Primary1);
      padding: 2px;
      border-radius: 5px;
      background: #fff;
    }
  }
  .app-section__top {
    margin-top: 81px;
  }
  .main-nav--link-help {
    .icon {
      display: none;
    }
  }
  .icon--text {
    display: block;
  }
  .header__arti {
    margin-left: 0;
    margin-top: 0;
    margin-right: 16px;
    width: 110px;
  }
  .header__gif {
    display: flex;
  }
}

/* Medium */
@media (max-width:992px) {

  .main-nav ul {
    overflow-y: scroll;
    margin-bottom: 72px;
  }

  .app-section {
    &.app--home {
      header {
        border-bottom: 0;
        
        &[data-class="false"] {
          background: #fff;
         
        }
      }
    }
  }
  .header__logo figure img {
    object-position: center;
  }
  
  .hero-image[data-auth=hero--logout] {
    margin-top: 140px;
  }
}

/* Small */
@media (max-width:680px) {
  .main-nav ul {
    width: 80%;
    li {
      a {
        &.btn,
        &.main-nav__login {
          width: 100%;
        }
      }
    }
  }
  header.header {
    padding: 0;
    &[data-class=false] {
      padding: 0;
      .header__search-bar {
        transform: translateY(-70px);
      }
    }
  }
  .header__top-row {
    padding: 10px 0;
  }
  .header__search-bar-item input {
    font-size: 15px;
    line-height: 40px;
  }
  .header__logo {
    width: 110px;
  }
  .main-nav ul  {
    li.main-nav__separator.main-nav__extras {
      padding: 30px 15px 10px 40px;
      border: 0;
      margin-bottom: 16px;
      margin-top: 0;
      margin-left: 0;
    }
  }
  
}