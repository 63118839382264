/* ------------------------------------------------------------
     SESSION BLOCK
------------------------------------------------------------ */
.session-block {
  border-radius: 10px;
  background: #fff;
  padding: 15px;
  box-shadow: 0 10px 10px rgba(0,0,0,.1);
  h6 {
    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding-right: 30px;
  }
  .btn--simple-icon  {
    background: var(--color-Primary1);
    width: 80px;
    padding: 1px;
    border-radius: 10px;
    justify-content: center;
    margin-top: 16px;
    color: #fff;
    font-size: 14px;
    span {
      text-decoration: none !important;
    }
  }
}
.session-block__info {
  display: flex;
  align-items: center;
}
.session-block__info-item {
  width: 50%;
  display: flex;
  align-items: center;
  span {
    font-size: 20px;
    margin-right: 16px;
    color: var(--color-Gray6)
  }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* large */
@media (max-width:1200px) {
 
}

/* medium */
@media (max-width:992px) {
  

}

/* small */
@media (max-width:680px) {

}