/* ------------------------------------------------------------
     VARIABLES
------------------------------------------------------------ */
// Base 

$c-Base: #1A1A1A;
// Secondary
$c-Primary1: #8439FF;
$c-Primary2: #9B75FD;
$c-Primary3: #B587FD;
$c-Primary4: #CEAFFE;
$c-Primary5: #E7D7FE;
$c-Primary6: #6F2ED2;
$c-Primary7: #8F62B1;
$c-Secondary1: #4b0082;
$c-Secondary2: #6E459C;
$c-Secondary3: #9466B4;
$c-Secondary4: #B799CD;
$c-Secondary5: #DBCCE6;
$c-Third1: #FFE919;
$c-Third2: #e0cc13;
$c-Third3: #fffbd7;
$c-Fourth1: #003980;
$c-Fourth2: #012480;
$c-Fourth3: #032FA7;
$c-Fourth4: #26428C;
$c-Fith1: #335099;
$c-Fith2: #677CB3;
$c-Fith3: #99A7CC;
$c-Fith4: #CCD3E6;
$c-Fith7: #dfe4f1;
$c-Fith5: #5578D0;
$c-Fith6: #586FAA;
$c-Six1: #A6E6E5;
$c-Six2: #B8EBEA;
$c-Six3: #CAF0EF;
$c-Seven2: #D8398E;
$c-Blue1: #00FFFF;
$c-Blue2: #01dfdf;
$c-Blue3: #A7E6E5;
$c-Blue4: #05E3E3;
$c-Pink100: #FD90FF;
$c-Pink200: #e778e9;

// Gray
$c-Gray1: #DEE6F2;
$c-Gray2: #D8DDE2;
$c-Gray3: #F3F5F9;
$c-Gray4: #C7CBD2;
$c-Gray5: #5D5D5D;
$c-Gray6: #6B81A5;
$c-Gray7: #95A1BB;
$c-Gray8: #ecedef;
$c-Gray9: #eeeeee;
$c-Gray10: #f5f5f5;
$c-Gray11: #C7CBD2;
$c-Gray12: #3D4043;

// Alerts
$c-Success1: #67BF78;
$c-Success2: #E5FBE8;
$c-Success3: #76EE59;
$c-Warning1: #fad66c;
$c-Danger1: #D93334;
$c-Danger2: #facfcf;
$c-Danger3: #e8bdbd;

// Social
$c-Facebook: #3b5998;
$c-Twitter: #00acee;
$c-Google: #EA4335;
$c-Windows: #00A1F1;

// Pacomax
$c-DarkBlue: #083DCD;
$c-DarkBlue2: #3140C1;

// Font

@font-face {
     font-family: 'GreycliffCF-Light';
     src: url('../../../../public/assets/fonts/GreycliffCF-Light.ttf') format('truetype');
     font-weight: 300;
     font-display: swap
}
@font-face {
     font-family: 'GreycliffCF-Regular';
     src: url('../../../../public/assets/fonts/GreycliffCF-Regular.ttf') format('truetype');
     font-weight: 300;
     font-display: swap
}
@font-face {
     font-family: 'GreycliffCF-Medium';
     src: url('../../../../public/assets/fonts/GreycliffCF-Medium.ttf') format('truetype');
     font-weight: 300;
     font-display: swap
}
@font-face {
     font-family: 'GreycliffCF-Bold';
     src: url('../../../../public/assets/fonts/GreycliffCF-Bold.ttf') format('truetype');
     font-weight: 300;
     font-display: swap
}
@font-face {
     font-family: 'Meutas-SemiBold';
     src: url('../../../../public/assets/fonts/Meutas-SemiBold.ttf') format('truetype');
     font-weight: 300;
     font-display: swap
}


$c-FontLight:  'GreycliffCF-Light', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; 
$c-FontRegular:  'GreycliffCF-Regular', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; 
$c-FontMedium:  'GreycliffCF-Medium', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; 
$c-FontBold:  'GreycliffCF-Bold', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; 
$c-FontTitle:  'Meutas-SemiBold', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; 

// Root
:root {

     // Colors
     --color-Base: #{$c-Base};
     --color-Primary1: #{$c-Primary1};
     --color-Primary2: #{$c-Primary2};
     --color-Primary3: #{$c-Primary3};
     --color-Primary4: #{$c-Primary4};
     --color-Primary5: #{$c-Primary5};
     --color-Primary6: #{$c-Primary6};
     --color-Primary7: #{$c-Primary7};
     --color-Secondary1: #{$c-Secondary1};
     --color-Secondary2: #{$c-Secondary2};
     --color-Secondary3: #{$c-Secondary3};
     --color-Secondary4: #{$c-Secondary4};
     --color-Secondary5: #{$c-Secondary5};
     --color-Third1: #{$c-Third1};
     --color-Third2: #{$c-Third2};
     --color-Third3: #{$c-Third3};
     --color-Fith1: #{$c-Fith1};
     --color-Fith2: #{$c-Fith2};
     --color-Fith3: #{$c-Fith3};
     --color-Fith4: #{$c-Fith4};
     --color-Fith5: #{$c-Fith5};
     --color-Fith6: #{$c-Fith6};
     --color-Fith7: #{$c-Fith7};
     --color-Fourth1: #{$c-Fourth1};
     --color-Fourth2: #{$c-Fourth2};
     --color-Fourth3: #{$c-Fourth3};
     --color-Fourth4: #{$c-Fourth4};
     --color-Six1: #{$c-Six1};
     --color-Six2: #{$c-Six2};
     --color-Six3: #{$c-Six3};
     --color-Seven2: #{$c-Seven2};
     --color-Gray1: #{$c-Gray1};
     --color-Gray2: #{$c-Gray2};
     --color-Gray3: #{$c-Gray3};
     --color-Gray4: #{$c-Gray4};
     --color-Gray5: #{$c-Gray5};
     --color-Gray6: #{$c-Gray6};
     --color-Gray7: #{$c-Gray7};
     --color-Gray8: #{$c-Gray8};
     --color-Gray9: #{$c-Gray9};
     --color-Gray10: #{$c-Gray10};
     --color-Gray11: #{$c-Gray11};
     --color-Success1: #{$c-Success1};
     --color-Success2: #{$c-Success2};
     --color-Success3: #{$c-Success3};
     --color-Warning1: #{$c-Warning1};
     --color-Danger1: #{$c-Danger1};
     --color-Danger2: #{$c-Danger2};
     --color-Danger3: #{$c-Danger3};
     --color-Facebook: #{$c-Facebook};
     --color-Twitter: #{$c-Twitter};
     --color-Google: #{$c-Google};
     --color-Windows: #{$c-Windows};
     --color-DarkBlue: #{$c-DarkBlue};
     --color-DarkBlue2: #{$c-DarkBlue2};
     --color-Blue1: #{$c-Blue1};
     --color-Blue2: #{$c-Blue2};
     --color-Blue3: #{$c-Blue3};
     --color-Blue4: #{$c-Blue4};
     --color-Pink100: #{$c-Pink100};
     --color-Pink200: #{$c-Pink200};

     // Date Picker
     --rs-bg-active: #{$c-Secondary1};
     --rs-btn-primary-bg: #{$c-Secondary1};
     --rs-input-focus-border: #{$c-Secondary1};

     // Fonts 
     --font-Light: #{$c-FontLight};
     --font-Regular: #{$c-FontRegular};
     --font-Medium: #{$c-FontMedium};
     --font-Bold: #{$c-FontBold};
     --font-Title: #{$c-FontTitle};
}