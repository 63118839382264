/* ------------------------------------------------------------
     PLANNING 2
------------------------------------------------------------ */
.planning__row {
  .planning__col-aside {
    padding-left: 60px;
    height: 300px;
    position: sticky;
    top: 60px;
    padding-top: 57px;
  }
}


/*
  :::::::
  Responsive
  :::::::
*/


/* Small medium */
@media (max-width:768px) {
  .planning__row {
    .planning__col-aside {
      padding: 0;
      height: auto;
      position: relative;
      top: 0;
      padding-top: 0;
    }
  }
}
   
/* Small */
@media (max-width:680px) {
  .planning__row {
    .planning__col-aside {
      padding: 0 15px;
    
    }
  }
     
}