/* ------------------------------------------------------------
     MATERIAL
------------------------------------------------------------ */
.material-added {
  width: 100%;
  padding: 10px 15px;
  background: var(--color-Gray3);
  border-radius: 0 0 10px 10px;
  a {
    color: var(--color-Base);
    text-decoration: none;
    transition: .4s all ease-in-out;
  }
  .check-like__change figure span {
    top: -1px;
  }
  .material-added__text {
    margin-bottom: 10px;
    p,
    h4 {
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      font-weight: 300;
    }
    h4 {
      font-size: 14px;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-weight: 500;
      &:not(.pacomax) {
        display: flex;
        span {
          font-size: 12px;
          margin-left: 4px;
          position: relative;
          top: 3px;
          color: var(--color-Gray5);
        }
      }
    
      &.pacomax {
        background: var(--color-Primary1);
        color: #fff;
        padding: 2px 5px;
        border-radius: 10px;
        width: 90px;
        text-align: center;
      }
    }
  }
}
.material-added__image {
  width: 100%;
  >figure {
    height: 100%;
    margin-bottom: 8px;
    height: 130px;
    overflow: hidden;
    border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
.material-added__col-rigth {
  position: relative;
}
.material-added__time {
  width: 100%;
  p {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    span {
      font-size: 22px;
      color: var(--color-Gray7);
      margin-right: 4px;
    }
  }
}
.material-added__delete {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-Danger1);
  border: 0;
  background: transparent;
  transition: .4s all ease-in-out;
  &:hover {
    opacity: .7;
  }
}
.material-added__add {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-Primary1);
  border: 0;
  background: transparent;
  transition: .4s all ease-in-out;
  &:hover {
    opacity: .7;
  }
}


/*
  :::::::
  Responsive
  :::::::
*/

/* large */
@media (max-width:1200px) {

}

/* Small */
@media (max-width:680px) {
  .material-added {
    padding-left: 0;
    padding-right: 0;
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}