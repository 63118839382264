/* ------------------------------------------------------------
    ACTIVITY 
------------------------------------------------------------ */
.title-activity {
  margin-bottom: 32px;
}
.game__article {
  padding: 20px;
}
.noanswer {
  padding: 0;
  margin: 100px 0;
  text-align: center;
  p {
    font-size: 18px;
  }
}
.game__answer {
  .accordion {
    padding: 20px; 
  }
  .accordion-header {
    background: var(--color-Primary1);
    border-radius: 10px;
    * {
      color: #fff;
    }
    button {
      padding: 0 10px;
    }
    .accordion-button:not(.collapsed) {
      background: var(--color-Primary3);
    }
  }
  .accordion-item {
    .accordion-body {
      padding: 20px;
    }
  }
}

/* pagination */
.pagination-activity {
  width: 100%;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 0 16px;
      &:last-child {
        button {
          span {
            width: 20px;
          }
        }
      }
    }
  }
  button {
    width: 50px;
    height: 50px;
    background: var(--color-Secondary5);
    border-radius: 10px;
    font-size: 40px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .4s all ease-out;
    &:hover {
      background: var(--color-Secondary4);
    }
    span {
      width: 10px;
      display: block;
    }
  }
}
.pagination-activity__indicator {
  width: 100%;
  p {
    color: var(--color-Fourth1);
    font-family: var(--font-Bold);
    text-align: center;
    margin: 0;
    &:not(.indicator__number) {
      color: var(--color-Fith2);
      line-height: 17px;
    }
  }
}
.indicator__number {
  font-size: 32px;
}


/* link referal */
.link-referal {
  .btn {
    padding: 0;
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    &:hover {
      border: 0;
      padding: 0;
      color: var(--color-Primary1);
    }
    span {
      font-size: 16px;
      margin-left: 4px;
    }
  }
}
.link-referal__item {
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:992px) {
}
   
/* medium */
@media (max-width:768px) {
  
}

/* Small */
@media (max-width:680px) {
  
}