/* ------------------------------------------------------------
     SEARCH SELECT
------------------------------------------------------------ */
.search-select {
     position: relative;
     
     .react-select__control {
          border-color: var(--color-Gray7);
          box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
          border-radius: 10px;
          &:focus {
               border-color: var(--color-Secondary2);
               box-shadow: 0 0 0 0.25rem rgb(96 129 226 / 8%);
          }
     }
     .react-select__input {
          box-shadow: initial;
     }
     .react-select__input-container  {
          margin: 0;
          padding: 0;
     }
     .react-select__value-container  {
          padding: 0 8px;
     }
     .react-select__indicator  {
          opacity: 0;
     }
}
.search-select__icon {
     position: absolute;
     top: 9px;
     right: 8px;
     z-index: 1;
     pointer-events: none;
     color: var(--color-Base);
}

.instrumentos .search-select .react-select__control {
     border-color: var(--color-Primary1);
     border-radius: 20px;
     border-width: 3px;
}