/* ------------------------------------------------------------
     STEPPER
------------------------------------------------------------ */
.stepper {
  width: 100%;
  margin-bottom: 60px;
  &[data-status="step-1"] {
    li {
      &:first-child {
        .stepper__span {
          background: var(--color-Fourth1);
          color: #fff;
        }
      }
    }
  }
  &[data-status="step-2"] {
    li {
      &:first-child,
      &:nth-child(2) {
        .stepper__span {
          background: var(--color-Fourth1);
          color: #fff;
        }
      }
    }
    ul {
      &:after {
        width: 80%;
      }
    }
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:before,
    &:after {
      content: '';
      height: 4px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
    &:before {
      width: 100%;
      background: var(--color-Gray3);
      
    }
    &:after {
      content: '';
      width: 0;
      background: var(--color-Fourth1);
      z-index: 1;
    }
    
    li {
      position: relative;
      z-index: 2;
      .stepper__span {
        width: 30px;
        height: 30px;
        font-size: 14px;
        background: var(--color-Gray3);
        display: flex;
        justify-content: center;
        border-radius: 50%;
        align-items: center;
        font-weight: bold;
      }
      small {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 36px;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        color: var(--color-Gray5);
        width: 72px;
        background: var(--color-Gray3);
        border-radius: 30px;
        padding: 1px;
      }
    }
  }
}

/*
:::::::
Responsive
:::::::
*/

/* Small medium */
@media (max-width:768px) {

}

/* Small */
@media (max-width:680px) {
  .stepper {
    margin-bottom: 30px !important;
    ul {
      li {
        &:last-child {
          small {
            right: 0;
            left: initial;
          }
        }
        small {
          left: 0;
          border-radius: 10px;
          transform: initial;
        }
      }
    }
  }
}