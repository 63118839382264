/* ------------------------------------------------------------
     BUTTON SOCIAL
------------------------------------------------------------ */
.btn {
  &.btn--social {
    background: var(--color-Gray3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 50px;
    width: 60px;
    i {
      &.fa-facebook-f {
        color: var(--color-Facebook);
      }
      &.fa-twitter {
        color: var(--color-Twitter);
      }
      &.fa-google {
        color: var(--color-Google);
      }
      &.fa-windows {
        color: var(--color-Windows);
      }
    }
    &:hover {
      background: var(--color-Gray1);
    }
  }
}