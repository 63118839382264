/* ------------------------------------------------------------
     BASE
------------------------------------------------------------ */
.app-main,
#root {
     width: 100%;
     height: 100%;
}
.app-section {
     width: 100%;
}
.app-section__top {
     margin-top: 91px;
}
.app--background {
     // background: url(../../../../public/assets/images/background/background-white.svg) center center no-repeat;
     background-size: cover;
     background-position: center;
}
.app-main {
     &.no-index-layout {
          padding-top: 85px;
     }
}

/* module */
.module {
     padding: 80px 0;
     width: 100%;
     &.module--color1 {
          background: var(--color-Gray3);
     }
}
.module-small {
     padding-top: 30px;
     padding-bottom: 100px;
}
.module__title {
     text-align: center;
     margin-bottom: 32px;
     a {
          color: var(--color-Primary1);
     }
     &.title--action {
          display: flex;
          justify-content: center;
          a {
               display: none;
          }
     }
     &.mb8 {
          margin-bottom: 8px;
     }
}
.module__action {
     display: flex;
     justify-content: center;
     flex-wrap: wrap;
     .btn {
          width: auto;
     }
     p {
          width: 100%;
          text-align: center;
          font-family: var(--font-Bold);
          margin-top: 16px;
     }
}
.module__message {
     text-align: center;
     margin-bottom: 32px;
}

.container-simple {
     padding-top: 60px;
     padding-bottom: 60px;
}

/* share col */
.share-col {
     display: flex;
     justify-content: center;
}

/* liker footer */
.liker-footer {
     display: flex;
     align-items: center;
     > * {
          margin-left: 0 !important;
          margin-right: 15px;
     }
}


/* titles */
.label-title {
     width: auto;
     background: var(--color-Primary1);
     display: inline-block;
     position: relative;
     padding: 8px 16px 14px 0;
     margin-bottom: 100px;
     &:before {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 200%;
          background: var(--color-Primary1);
     }
     &.label--color1 {
          background: var(--color-Secondary1);
          &:before {
               background: var(--color-Secondary1);
          }
         
     }
     &.label--color2 {
          background: var(--color-Fourth3);
          &:before {
               background: var(--color-Fourth3);
          }
         
     }
     * {
          color: #fff;
          position: relative;
          z-index: 1;
     }
     span {
          color: var(--color-Blue1)
     }
     h2 {
          font-family: var(--font-Medium);
     }
}

/* single title */
.single-title {
     color: var(--color-Primary1);
     font-size: 40px;
     line-height: 45px;
     font-family: var(--font-Regular);
}