/* ------------------------------------------------------------
     RESULT BLOCK
------------------------------------------------------------ */
.result-block__wrapper {
  width: 100%;
}
.result-block {
  padding: 60px 0;
  border-top: 1px solid var(--color-Gray2);
  &:first-child {
    border-top: 0;
  }
}
.result-block__action {
  padding-top: 0 !important;
  border-top: 0;
}
.result-block__action-padding {
  border-top: 0;
}
.result-block__title {
  h1 {
    font-size: 24px;
    line-height: 30px;
  }
  
}
.result-block__row {
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-Gray6);
  }
  h4 {
    font-size: 16px;
    font-weight: 400;
    small {
      font-weight: 600;
    }
  }
  article {
    p {
      margin-bottom: 16px;
    }
  }
}
.result-block__col {
  h3 {
    font-size: 18px;
    color: var(--color-Secondary1);
  }
  .badge {
    margin-bottom: 16px;
  }
}
.result-block__share {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn {
    margin-left: 16px;
  }
}
.result-block__li {
  list-style: none;
  padding: 0;
  margin: 8px 0 0 0;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      color: var(--color-Fourth1);
      font-size: 16px;
      margin-right: 4px;
    }
    p {
      color: var(--color-Base);
    }
  }
}

/* item added */
.item-added {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  .item__name {
    background: var(--color-Gray2);
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 14px;
    display: inline-block;
    margin-right: 16px;
  }
}
.item__delete {
  border: 0;
  background: var(--color-Danger2);
  color: var(--color-Danger1);
  border-radius: 10px;
  height: 35px;
  padding: 0 10px;
  transition: .4s all ease-in-out;
  &:hover {
    background: var(--color-Danger3);
  }
  span {
    background: transparent;
    padding: 0;
    border-radius: 0;
    display: block;
    margin: 0;
    font-size: 20px;
  }
}
.result-block__planning {
  margin-bottom: 32px;
}

/* Session layout */
.session-head {
  padding-bottom: 16px;
  margin-bottom: 0;
}
.session-options {
  background: var(--color-Gray3);
  padding-top: 10px;
    padding-bottom: 15px;
}
.session-data {
  
  padding-bottom: 16px;
}
.session-container {
  background: var(--color-Gray3);
  border-radius: 20px;
}
.result-block__session-col {
  margin-bottom: 24px;
  position: relative;
}
.session-block__delete {
  border: 0;
  background: transparent;
  position: absolute;
  top: 10px;
  right: 20px;
  color: var(--color-Danger1);
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  &:hover {
    background: var(--color-Gray3);
  }
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Medium */
@media (max-width: 992px) {
  .result-block__share {
    justify-content: flex-start;
    margin-top: 16px;
    .btn {
      margin-right: 16px;
      margin-left: 0;
    }
  }
}
/* Small medium */
@media (max-width:768px) {
  .result-block {
    padding: 30px 0;
  }
  .result-block__title h1 {
    font-size: 18px;
  }
  .result-block__col {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    .badge {
      margin-bottom: 8px;
    }
  }
}
   
/* Small */
@media (max-width:680px) {
  .result-block__row {
    margin-left: 0;
    margin-right: 0;
  }
  .result-block__share {
    justify-content: space-between;
    .btn {
      margin-right: 0;
      margin-left: 0;
    }
  }
  .resutl-block__col-add {
    display: flex;
    justify-content: center;
  }
  .result-block__planning {
    margin-bottom: 0px;
  }
}