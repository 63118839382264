.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}
.react-pdf__Page {
  width: 100% !important;
  min-width: initial !important;
}
.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none !important;
}

.react-pdf__Page__textContent span {
	opacity: 0.1;
}

.react-pdf__Page__textContent span::selection {
	background-color: blue;
}

.react-pdf__Document {
	line-height: initial;
}

.arrows-pdf {
  button {
    font-size: 30px;
    margin: 0 5px;
    padding: 5px;
  }
}