/* ------------------------------------------------------------
     CONTAINER SIMPLE
------------------------------------------------------------ */
.container-simple {
  hr {
    display: none;
  }
  h1 {
    font-size: 32px;
  }
  h4 {
    font-size: 18px;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 24px;
  }
}


@media (max-width: 768px) {
  .container-simple {
    h1 {
      font-size: 24px;
    }
  }
}