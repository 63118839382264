/* ------------------------------------------------------------
     HOME
------------------------------------------------------------ */
.center-welcome {
  width: 100%;
  padding: 220px 0 40px 0;
}
.center-welcome__title {
  color: var(--color-Secondary1);
  font-family: var(--font-Bold);
  span {
    background: var(--color-Secondary1);
    color: #fff;
  }
}

.center-welcome__card {
  padding: 20px;
  // background: #fff;
  border-radius: 12px;
  // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: relative;
}
.center-welcome__article {
  padding-top: 24px;
  p {
    font-size: 24px;
    line-height: 35px;
    font-family: var(--font-Medium);
  }
  .welcome-small {
    font-size: 20px;
  }
}
.center-welcome__image {
  margin: 0;
  position: absolute;
  bottom: 0;
  right: 50px;
  width: 167px;
  img {
    width: 100%;
  }
}

/* grid services */
.grid-services {
  width: 100%;
  padding-bottom: 40px;
  &.grid--quick {
    padding-top: 120px;
  }
}
.grid-services__btn {
  background: var(--color-Gray1);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  height: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transform: scale(1);
  transition: .4s all ease-in-out;
  &.btn--color1 {
    color: var(--color-Fourth2);
    background: var(--color-Blue3);
  }
  &.btn--color2 {
    color: #fff;
    background: var(--color-Secondary1);
  }
  &.btn--color3 {
    color: #fff;
    background: var(--color-Primary1);
  }
  &.btn--color4 {
    color: #fff;
    background: var(--color-Fourth2);
  }
  &:hover {
    transform: scale(1.1);
  }
  p {
    padding-left: 10px;
    width: 80%;
    font-size: 19px;
    line-height: 20px;
    font-family: var(--font-Bold);
  }
  span {
    color: var(--color-Blue1);
  }
  .icon-ia {
    width: 70%;
  }
}
.grid-services__col {
  height: 130px;
  margin-bottom: 32px;
}

/*
  :::::::::
  RESPONSIVE
  ::::::::
*/
@media (max-width: 1400px) {
  .grid-services__col {
    height: 150px;
  }
}
@media (max-width: 1200px) {
  .icon-ia {
    width: 69%;
  }
  .grid-button__image {
    top: 26%;
  }
.grid-services__btn {
  p {
    font-size: 16px;
  }
}
}
@media (max-width: 992px) {
  .center-welcome__image {
    right: 40px;
    width: 140px;
  }
}
@media (max-width: 768px) {
  .center-welcome__image {
    display: none;
  }
  .center-welcome__article {
    p {
      font-size: 18px;
      line-height: 25px;
    }
  }
  .grid-services.grid--quick {
    padding-top: 100px;
    padding-bottom: 80px
  }
  .grid-button__image {
    display: none;
  }
  .icon-ia {
    width: 72%;
  }
}
@media (max-width: 680px) {
  .center-welcome__article {
    p {
      font-size: 16px;
    }
  }
  .center-welcome {
    padding: 190px 0 0px 0;
  }
  .center-welcome__card {
    padding: 15px;
  }
  .grid-services {
    padding-top: 40px;
    padding-bottom: 0;
  }
  .grid-services__col {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 450px) {
  .grid-services__btn {
    padding: 20px 10px;
    p {
      font-size: 15px;
      line-height: 19px;
    }
  }
}