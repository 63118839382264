/* ------------------------------------------------------------
     CHAT GPT
------------------------------------------------------------ */
.chat-section {
    position: absolute;
    top: 0px;
    right: -20px;
    transition: .4s all ease-in-out;
    &.disabled {
      pointer-events: none;
      opacity: .8;
    }
}
.chat-button {
  background: transparent;
  border: 0;
  cursor: pointer;
  width: 105px;
  position: absolute;
  top: 0;
  right: 0;
  &:hover {
    figure {
      transform: scale(1.1);
    }
  }
  figure {
    margin: 0;
    width: 100%;
    transform: scale(1);
    transition: .4s all ease-in-out;
    img {
      width: 100%;
    }
  }
}
.chat-text-button {
  background: transparent;
}
.chat-info {
  background: var(--color-Blue1);
  border: 3px solid var(--color-Blue4);
  border-radius: 30px;
  padding: 5px 70px 5px 18px;
  margin-right: 20px;
  box-shadow: 0 10px 10px rgba(0,0,0,.2);
  h6 {
    color: var(--color-Fourth1);
    line-height: 20px;
    font-size: 14px;
  }
  p {
    color: var(--color-Secondary1);
    font-size: 13px;
    line-height: 18px;
    font-family: $c-FontBold;
  }
}
.chat-options {
  position: absolute;
  top: 60px;
  left: -90px;
  width: 250px;
  background: var(--color-Third3);
  border-radius: 10px;
  border: 3px solid var(--color-Third1);
  padding: 10px;
  box-shadow: 0 10px 10px rgba(0,0,0,.2);
  h6 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  small {
    font-size: 11px;
  }
}
.chat-options__buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  button {
    background: transparent;
    color: var(--color-Fourth1);
    cursor: pointer;
    border: 0;
    text-decoration: underline;
    font-size: 14px;
    padding: 0;
    &:hover {
      text-decoration: none;
    }
  }
}


/*
::::::

RESPONSIVE

::::::
*/

@media (max-width: 680px) {
  .chat-options {
    left: -70px;
  }
  .planning-row-form .chat-info {
    display: none;
  }
  .chat-button {
    width: 85px;
  }
}
