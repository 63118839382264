/* ------------------------------------------------------------
     ICON
------------------------------------------------------------ */
.icon-item {
  display: inline-block;
}
.icon--explain {
  width: 25px;
  height: 25px;
  background: url(../../../../public/assets/images/icon/explicaciones.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--play {
  width: 25px;
  height: 25px;
  background: url(../../../../public/assets/images/icon/play.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--play {
  width: 25px;
  height: 25px;
  background: url(../../../../public/assets/images/icon/play.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--videogame {
  width: 25px;
  height: 25px;
  background: url(../../../../public/assets/images/icon/videogame.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--list {
  width: 25px;
  height: 25px;
  background: url(../../../../public/assets/images/icon/paso-paso.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--infographic {
  width: 25px;
  height: 25px;
  background: url(../../../../public/assets/images/icon/infografias.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--camera {
  width: 25px;
  height: 25px;
  background: url(../../../../public/assets/images/icon/video.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--checklist {
  width: 25px;
  height: 25px;
  background: url(../../../../public/assets/images/icon/checklist.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--button1 {
  width: 29px;
  height: 29px;
  background: url(../../../../public/assets/images/icon/icon-button1.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--button2 {
  width: 29px;
  height: 29px;
  background: url(../../../../public/assets/images/icon/icon-button2.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--button3 {
  width: 29px;
  height: 29px;
  background: url(../../../../public/assets/images/icon/icon-button3.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--button4 {
  width: 29px;
  height: 29px;
  background: url(../../../../public/assets/images/icon/icon-button4.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--button5 {
  width: 29px;
  height: 29px;
  background: url(../../../../public/assets/images/icon/icon-button5.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--button6 {
  width: 29px;
  height: 29px;
  background: url(../../../../public/assets/images/icon/icon-button6.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--button7 {
  width: 29px;
  height: 29px;
  background: url(../../../../public/assets/images/icon/icon-button7.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--button8 {
  width: 25px;
  height: 25px;
  background: url(../../../../public/assets/images/icon/icon-button8.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--button9 {
  width: 25px;
  height: 25px;
  background: url(../../../../public/assets/images/icon/icon-button9.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon--button10 {
  width: 25px;
  height: 25px;
  margin-right: 12px !important;
  background: url(../../../../public/assets/images/icon/chat.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* new icons dashboard */
.icon--grid1 {
  width: 28px;
  height: 28px;
  background: url(../../../../public/assets/images/icon/new-icons/icon1.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
.icon--grid2 {
  width: 28px;
  height: 28px;
  background: url(../../../../public/assets/images/icon/new-icons/icon2.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
.icon--grid3 {
  width: 28px;
  height: 28px;
  background: url(../../../../public/assets/images/icon/new-icons/icon3.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
.icon--grid4 {
  width: 28px;
  height: 28px;
  background: url(../../../../public/assets/images/icon/new-icons/icon4.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
.icon--grid5 {
  width: 28px;
  height: 28px;
  background: url(../../../../public/assets/images/icon/new-icons/icon5.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
.icon--grid6 {
  width: 28px;
  height: 28px;
  background: url(../../../../public/assets/images/icon/new-icons/icon6.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
.icon--grid7 {
  width: 28px;
  height: 28px;
  background: url(../../../../public/assets/images/icon/new-icons/icon7.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
.icon--grid8 {
  width: 28px;
  height: 28px;
  background: url(../../../../public/assets/images/icon/new-icons/icon8.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
.icon--grid9 {
  width: 28px;
  height: 28px;
  background: url(../../../../public/assets/images/icon/new-icons/icon9.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
