/* ------------------------------------------------------------
     FORMS
------------------------------------------------------------ */
.form__title {
  text-align: center;
  margin-bottom: 32px;
  font-family:  var(--font-Title);
}
.form__title-message {
  text-align: center;
}
.form__row {
  margin-bottom: 24px;
}
.form__label {
  font-weight: 600;
  margin-bottom: 8px;
  small {
    color: var(--color-Gray7);
  }
}
.required {
  color: var(--color-Seven2);
}
.form-init {
  width: 100%;
}
.form__action {
  display:  flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 32px;
  >div {
    display:  flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.form__action--both {
    .btn {
      margin: 0 16px;
    }
  }
}
.form__text {
  width: 100%;
  margin-bottom: 24px;
}
.form-check-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  .form-check {
    margin-right: 32px;
  }
}

/* input password */
.form-control__password {
  position: relative;
}
.form-control__password-action {
  position: absolute;
  top: 0;
  border: 0;
  right: 0;
  background: transparent;
  height: 40px;
  display: flex;
  transition: .4s all ease-in-out;
  align-items: center;
  &:hover {
    opacity: .7;
  }
  &[data-active="visible"]{
    .form-control__password-off {
      display: block;
    }
    .form-control__password-visible {
      display: none;
    }
  }
  span {
    color: var(--color-Base);
  }
}
.form-control__password-off {
  display: none;
}


/* multi search select */
.react-select__value-container {
  .react-select__multi-value {
    border-radius: 10px;
    background-color: var(--color-Six2);
    .react-select__multi-value__label {
      color: var(--color-Base);
    }
    .react-select__multi-value__remove{
      &:hover {
        background-color: transparent;
        opacity: .5;
      }
      svg {
        color: var(--color-Base);
      }
    }
  }
}

/* search sort select */
.search__sort-select {
  label {
    font-weight: 600;
    margin-bottom: 8px;
  }
}


.form-check-group {
  &.form-check--wrap {
    flex-wrap: wrap;
    .form-check label {
      font-weight: 500;
    }
  }
}


/* toggle info */
.toggle-info {
  background: #fff;
  display: inline-block;
  border: 1px solid var(--color-Gray4);
  border-radius: 10px;
  padding: 5px 10px;
  p {
    margin-bottom: 0;
  }
}


/* radio button */
.form-check-input:checked {
  background-color: var(--color-Primary1);
  border-color: var(--color-Primary1);
  
}


/* form validation inputs */
.form__validation-input {
  position: relative;
  &[data-alert="error"] {
    .js-alert-none {
      display: block;
    }
    .js-alert-error {
      display: flex !important;
    }
  }
  &[data-alert="success"] {
    .js-alert-error {
      display: none;
    }
    .js-alert-success {
      display: block !important;
    }
  }
  &.form--validator-password {
    .input-success {
      right: 32px;
    }
  }
}
.js-alert-error,
.js-alert-success {
  display: none !important;
}
.input-success {
  position: absolute;
  top: 8px;
  right: 8px;
  pointer-events: none;
  span {
    color: $c-Success1;
  }
}

/* Multicheckboxes */
.multicheckboxes {
  display: flex;
  .form-check {
    flex: 1 0 0%;
  }
}

/* toggle action */
.toggle-action {
  position: relative;
  input[type=checkbox] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    padding: 0;
    appearance: none;
    &:checked {
      & + div {
        background: var(--color-Success3);
        figure {
          left: 22px;
        }
      }
    }
  }
  div {
    box-shadow: inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1);
    background: var(--color-Gray2);
    width: 40px;
    height: 20px;
    border-radius: 30px;
    position: relative;
    figure {
      width: 16px;
      height: 16px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
      filter: drop-shadow(-2px 1px 6px rgba(0, 0, 0, 0.25));
      transition: .4s all ease-in-out;
    }
  }
}
.toggle-complete {
  display: flex;
  align-items: center;
  cursor: pointer;
  label {
    font-family: var(--font-Bold);
    display: flex;
    align-items: center;
    small {
      color: var(--color-Primary1);
    }
  }
  .toggle-action {
    margin-right: 6px;
  }
}

/* new forms */
.form-input-element {
  text-align: left;
  position: relative;
  .form-label {
    font-family: var(--font-Bold);
    display: flex;
    align-items: center;
    text-align: left;
    small {
      color: var(--color-Primary1);
    }
  }
  .form-control {
    border: 2px solid var(--color-Gray7);
    border-radius: 8px;
  }
  .accordion-title__extra {
    margin-left: 4px;
    color: var(--color-Gray7);
  }
}

.form-select,
.form-check-input[type=checkbox]  {
  border: 2px solid var(--color-Gray7);
  border-radius: 8px;
}

/* form group */
.form-group {
  label {
    font-weight: bold;
    margin-bottom: 8px;
    font-family: var(--font-Bold);
    display: flex;
    flex-wrap: wrap;
  }
  .form-check {
    label.form-check-label {
      font-family: var(--font-Regular);
      font-weight: 300;
    }
  }
}
.form-control,
.form-select {
  border: 2px solid var(--color-Gray7);
}

/* CK Editor */
.ck-editor {
  .ck.ck-toolbar {
    border: 2px solid var(--color-Gray7);
    border-radius: 10px 10px 0 0 !important;
  }
  .ck-blurred {
    border: 2px solid var(--color-Gray7) !important;
    border-radius: 0 0 10px 10px !important;
  }
}
.ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
  top: 10% !important;
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
  .form-check-group {
    .form-check {
      margin-bottom: 16px;
    }
  }
  .form__action.form__action--both {
    .btn {
    
      &.btn--type-outline {
        order: 2;
      }
      &.btn--type3 {
        order: 1;
        margin-bottom: 24px;
      }
    }
  }
}
   
/* Small */
@media (max-width:680px) {
  .form-check-group {
    .form-check {
      width: 100%;
    }
  }
  .form__action.form__action--both {
    .btn {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .new-accordion-planning {
    .accordion-item .accordion-header button {
      padding-top: 8px;
      padding-bottom: 8px;
      p {
        font-size: 14px;
      }
    }
    .accordion-body {
      padding: 16px;
    }
  }
}