/* ------------------------------------------------------------
    SEARCH 
------------------------------------------------------------ */
.search-block {
  width: 100%;
  margin-bottom: 100px;
}
.search__title {
  padding: 60px 0 40px 0;
  h1 {
    font-size: 32px;
    line-height: 35px;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    color: var(--color-Gray5);
    li {
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.search-block__top-col  {
  margin-bottom: 16px;
}
.search__engine {
  .search-select {
    margin-bottom: 16px;
  }
  p {
    font-size: 14px
  }
}
.search__engine-clear {
  border: 0;
  background: transparent;
  text-decoration: underline;
  padding: 0;
  margin-bottom: 16px;
  color: var(--color-Gray7);
  &:hover {
    text-decoration: none;
    color: var(--color-Gray6);
  }
}
.search__engine-sortable {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: -23px;
  position: relative;
  .btn {
    margin-left: 16px;
    margin-top: 28px;
  }
}
.filter-open-container {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--color-Gray3);
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
  transition: .4s all ease-in-out;
  z-index: 2;
  &[data-open="active"] {
    transform: scale(1);
    opacity: 1;
    pointer-events: initial;
  }
}
.filter-open-container__row {
  width: 100%;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-Gray4);
  .form-check {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.filter-open-container__multiple {
  display: flex;
  justify-content: space-between;
}
.filter-open-container__action {
  width: 100%;
  display: flex;
  justify-content: center;
  .btn {
    margin-top: 0;
    height: 40px;
  }
}
.filter-open-container {
  .btn--simple-filter {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
.filter-open-contaner__inner {
  width: 100%;
 
}

.search-block__results {
  width: 100%;
  margin-bottom: 32px;
  border-top: 1px solid var(--color-Gray4);
  padding-top: 32px;
}
.session-block__results__col {
  margin-bottom: 24px;
  > p {
    color: var(--color-Gray7);
    font-weight: 600;
  }
}
.search__inner-page {
  .form-control {
    border-color: var(--color-Primary1);
    height: 50px;
    border-width: 3px;
    border-radius: 30px;
  }
  .search-select__icon {
    top: 14px;
  }
}

/* search pills */
.search-pills {
  width: 100%;
  margin-bottom: 16px;
}
.search-pills__item {
  background: var(--color-Fith1);
  border-radius: 30px;
  padding: 5px 10px;
  display: inline-block;
  color: #fff;
  margin-bottom: 8px;
  margin-right: 16px;
  p {
    margin: 0;
    color: #fff;
  }
}


/* Search global */
.search__global-filters {
  .row {
    align-items: flex-end;
  }
}

/* resource message */
.resource-message {
  width: 100%;
  margin: 24px 0;
  display: flex;
  justify-content: center;
  article {
    background: var(--color-Primary4);
    padding: 20px 40px;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    .btn {
      margin-top: 24px;
    }
  }
}
.resource-message__icon {
  figure {
    width: 100%;
    margin: 0;
    img {
      width: 100px;
      border-radius: 50%;
    }
  }
}
.resource-message__text {
  padding-left: 24px;
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:992px) {
  .search__title h1 {
    font-size: 24px;
  }
  .search__title {
    padding-top: 30px;
  }
  .filter-open-container {
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    left: 0;
    height: 100%;
    z-index: 999;
    background: rgb(7 17 46 / 41%);
    backdrop-filter: blur(3px);
    border-radius: 0;
    box-shadow: initial;
  }
  .filter-open-contaner__inner {
    background: var(--color-Gray3);
    padding: 15px;
    border-radius: 10px;
    height: 100%;
  }
}
   
/* mediun */
@media (max-width:768px) {
  .search__title {
    padding-bottom: 0;
  }
  .search__engine-sortable {
    top: 0;
    justify-content: flex-start;
    .btn {
      margin: 0;
    }
    .btn--filter {
      background: var(--color-Gray8);
    }
  }
  .filter-open-container {
    .btn--simple-filter {
      top: 30px;
      right: 30px;
    }
  }
}

/* Small */
@media (max-width:680px) {

  .search__engine-sortable {
    top: 0;
    justify-content: space-between;
  }
  .resource-message article {
    justify-content: center;
  }
  .resource-message__icon {
    margin-bottom: 24px;
  }
}