/* ------------------------------------------------------------
     BUTTON TYPE 2
------------------------------------------------------------ */
.btn {
  &.btn--type3 {
    background: var(--color-Primary1);
    font-weight: 600;
    color: #fff;
    border-radius: 50px;
    height: 50px;
    width: 250px; 
    transition: .4s all ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: var(--color-Primary2);
      color: #fff;
    }
  }
}