/* ------------------------------------------------------------
     BUTTON ADD
------------------------------------------------------------ */
.btn {
  &.btn--add {
    display:  flex;
    align-items: center;
    color: var(--color-Fith1);
    padding: 0;
    border: 0;
    &:hover {
      span {
        background: var(--color-Fith2);
      }
      p {
        color:  var(--color-Fith2);
      }
    }
    span {
      background: var(--color-Fith1);
      color: #fff;
      width: 30px;
      height: 30px;
      margin-right: 8px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .4s all ease-in-out;
    }
    p {
      margin: 0;
      font-weight: 600;
      color: var(--color-Fith1);
      transition: .4s all ease-in-out;
    }
  }
}

@media (max-width: 680px) {
  .btn {
    &.btn--add {
      p {
        text-align: left;
      }
    }
  }
}