/* ------------------------------------------------------------
     Modal sug
------------------------------------------------------------ */

.modal--sug {
     .modal-dialog {
          max-width: 345px;
          
     }
     .modal-content {
          box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid var(--color-Gray2);
     }
     .modal-header  {
          position: relative;
         
     }
     .modal-title  {
          width: 100%;
          padding: 30px 0 30px 10px;
          border-bottom: 1px solid var(--color-Gray2);
          * {
               color: var(--color-Fourth1);
               font-family: var(--font-Medium);
          }
     }
     .modal__close {
          position: absolute;
          top: -20px;
          right: -20px;
     }
     .modal-footer {
          border-top: 0;
          padding-top: 0;
          justify-content: center;
     }
}
.modal-sug-image-back {
     position: absolute;
     width: 100%;
     margin: 0;
     height: 120px;
     padding: 0 25px 0 0;
     img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: right;
     }
}
.modal__close {
     &.close--red {
          background: var(--color-Seven2);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          span {
               color: #fff;
          }
     }
}
.modal__sug-top {
     h4 {
          color: var(--color-Fourth1);
          font-family: var(--font-Medium);
     }
}
.modal__sug-middle {
     padding: 5px 0 24px 0;
     h6 {
          padding-bottom: 5px;
          color: var(--color-Fourth1);
          font-family: var(--font-Medium);
     }
}
.modal__sug-bottom {
     * {
          color: var(--color-Fourth1);
          font-size: 14px; 
          font-family: var(--font-Medium);   
     }
     a {
          text-decoration: underline;
          &:hover {
               text-decoration: none;
          }
     }
}

/*
  :::::::
  Responsive
  :::::::
*/

   
/* Small */
@media (max-width:680px) {
     .modal--sug {
          .modal-dialog {
               margin: 0 auto;
          }
          .modal__close {
               position: absolute;
               top: -10px;
               right: -10px;
           }
     }
     .modal-sug-image-back  {
          height: 100px;
     }
}