/* ------------------------------------------------------------
     Modal
------------------------------------------------------------ */
.modal-backdrop {
     background: var(--color-Secondary3) !important;
}
.modal--game {
     .btn-close {
          opacity: 1;
     }
     .modal-body {
          text-align: center;
          h4 {
               margin-bottom: 32px;
          }
          p {
               margin-bottom: 8px;
          }
     }
     
     .modal-content {
          border-radius: 10px;
          box-shadow: 0 10px 30px rgb(0 0 0 / 20%);
          border: 0;
     }
}

.modal__game {
     width: 100%;
     height: auto;
     border-radius: 10px;
     overflow: hidden;
     margin-bottom: 0;
     img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
     }
    
}

/*
  :::::::
  Responsive
  :::::::
*/

/* Small medium */
@media (max-width:768px) {
    
}
   
/* Small */
@media (max-width:680px) {
    
}