/* ------------------------------------------------------------
     Modal Options
------------------------------------------------------------ */

.modal--options {
     &.modal--left {
          .modal-body {
               text-align: left;
          }
     }
     &.modal--white {
          .modal-body {
               background: #fff;
          }
     }
  .modal__title-icon.modal-header {
       padding-bottom: 16px;
  }
  .modal-title {
       display: flex;
       align-items: center;
       h4 {
            margin: 0;
            font-family:  var(--font-Title);
       }
  }
  .modal__icon {
       width: 50px;
       margin-bottom: 0;
       margin-right: 8px;
  }
  .modal-body {
       text-align: center;
       h5 {
            margin-bottom: 16px;
       }
       p {
            margin-bottom: 8px;
       }
  }
  .modal-footer {
       border-top: 0;
       display:flex;
       padding-bottom: 24px;
       justify-content: center;
       
  }
  .modal-content {
       border-radius: 10px;
       box-shadow: 0 10px 30px rgb(0 0 0 / 20%);
       border: 0;
  }
  .modal-header {
     border-bottom: 1px solid var(--color-Gray4);
  }
}
.modal__button {
 padding: 20px;
 width: 100%;
 background: #fff;
 text-align: left;
 border: 0;
 transition: .4s all ease-in-out;
 position: relative;
 &:hover {
       background: var(--color-Gray3);
 }
 p {
  font-weight: 600;
  margin: 0 !important;
 }
 small {
  color: var(--color-Gray6);
 }
 .icon {
       position: absolute;
       top: 50%;
       transform: translateY(-50%);
       right: 15px;
       font-size: 28px;
 }
}
.modal__actions {
  width: 100%;
}
.modal__actions-inner {
  border-bottom: 1px solid var(--color-Gray4);
  &:last-child {
       border-bottom: 0;
  }
}

/*
:::::::
Responsive
:::::::
*/

/* Small medium */
@media (max-width:768px) {
 
}

/* Small */
@media (max-width:680px) {
 
}